import { useState } from 'react';
import { AspectRatio, Box, Spinner } from '@chakra-ui/react';

const Map = () => {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = () => {
    setIsLoading(false);
  };

  return (
    <Box
      w="90%"
      h={['80vh', '85vh']}
      marginY="2%"
      backgroundColor="#011B28E5"
      filter="drop-shadow(0px 0px 10px #26ACFD)"
      backdropFilter="blur(25.6px)"
      border="2px solid #26ACFD"
      borderRadius="20px"
      alignSelf="center"
      overflow="hidden"
    >
      {isLoading && (
        <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" zIndex="1">
          <Spinner size="xl" color="#26ACFD" />
        </Box>
      )}
      <AspectRatio ratio={16 / 9} w="100%" h="100%">
        <iframe
          title="Map"
          src="https://app.mappedin.com/map/671be3b692f239000b508f87?floor=m_4a48af8ed0d3e3e8"
          allowFullScreen
          onLoad={handleLoad}
          style={{
            borderRadius: '18px',
          }}
        />
      </AspectRatio>
    </Box>
  );
};

export default Map;
