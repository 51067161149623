import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import ProfileService from '../services/ProfileService';
import { useAuth } from 'react-oidc-context';

interface MessageModalProps {
  isOpen: boolean;
  type: 'kick' | 'leave' | 'delete';
  userName?: string;
  onClose: () => void;
  onConfirm: () => void;
}

const MessageModal = ({ type, isOpen, userName, onClose, onConfirm }: MessageModalProps) => {
  const [isCaptain, setIsCaptain] = useState(false);
  const [error, setError] = useState('');
  const auth = useAuth();

  useEffect(() => {
    const fetchTeamData = async () => {
      const token = auth.user?.access_token ?? '';
      try {
        const teamData = await ProfileService.fetchTeamData(token);
        const userId = auth.user?.profile.sub; // Assuming the user's ID is stored in `sub`

        // Check if the current user is the team captain
        if (teamData.capitanId === userId) {
          setIsCaptain(true);
        } else {
          setIsCaptain(false);
        }
      } catch (error) {
        setError('Failed to fetch team data.');
        console.error('Failed to fetch team data:', error);
      }
    };

    if (isOpen && auth.user?.access_token) {
      fetchTeamData(); // Fetch team data when the modal is opened
    }
  }, [isOpen, auth.user?.access_token]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        bg="rgba(8, 114, 82, 1)"
        borderColor="rgba(32, 177, 130, 1)"
        borderWidth="2px"
        borderRadius="20px"
        color="white"
        boxShadow="0 0 40px rgba(32, 177, 130, 1)"
      >
        <ModalHeader>
          {type === 'delete'
            ? 'Șterge contul'
            : type === 'kick'
              ? 'Kick'
              : isCaptain
                ? 'Șterge echipa'
                : 'Părăsește echipa'}
        </ModalHeader>
        <ModalBody>
          <Text>
            {type === 'delete'
              ? 'Ești sigur?'
              : type === 'kick'
                ? `Ești sigur că vrei să elimini coechipierul? ${userName}`
                : isCaptain
                  ? 'Ești căpitanul echipei. Dacă părăsești echipa aceasta va fi ștearsă permanent. Ești sigur că vrei să continui?'
                  : 'Ești sigur că vrei să îți părăsești echipa?'}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="transparent"
            borderColor="gray.400"
            borderWidth="1px"
            color="white"
            _hover={{ bg: 'gray.600' }}
            onClick={onClose}
            mr={3}
          >
            Anulează
          </Button>
          <Button bg="red.500" color="white" _hover={{ bg: 'red.600' }} onClick={onConfirm}>
            {type === 'delete'
              ? 'Șterge'
              : type === 'kick'
                ? 'Confirmă'
                : isCaptain
                  ? 'Șterge echipa 😞'
                  : 'Părăsește echipa 😞'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MessageModal;
