import { Box } from '@chakra-ui/react';

const GradientBlur = () => {
  return (
    <Box
      zIndex="-1"
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="70vh"
      background="linear-gradient(to bottom,rgba(5,50,48,1) -100%, rgba(0,22,35,1) 100%)"
      style={{
        WebkitMaskImage: 'linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,1))',
        maskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.1))',
      }}
    ></Box>
  );
};

export default GradientBlur;
