import { VStack } from '@chakra-ui/react';
import Contact from '../Contact/Contact';
import Events from '../Events/Events';
import Home from '../Home/Home';
import Sponsors from '../Sponsors/Sponsors';

import background from '../../common/assets/background.png';
import FeedbackCarousel from '../FeedbackCarousel/FeedbackCarousel';
import About from '../About/About';
import FAQ from '../FAQ/FAQ';

const Landing = () => {
  return (
    <VStack
      bgImage={background}
      bgRepeat="repeat"
      bgSize="contain"
      bgPosition="center"
      scrollBehavior="smooth"
    >
      <Home />
      <About />
      <Events />
      <Sponsors />
      <FeedbackCarousel />
      <FAQ />
      <Contact />
    </VStack>
  );
};

export default Landing;
