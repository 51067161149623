import { useBreakpointValue, Container } from '@chakra-ui/react';
import { ArrowButton } from '../../../common/components/Button/ArrowIconButton';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Next from '../assets/RightArrow.svg';
import Prev from '../assets/LeftArrow.svg';
import CarouselCard from './CarouselCard';

// When changing images, just change the pulic folder images and make sure they have the appropiate name
const imagePath = '/images/meetTheTeam/';

const teamMembers = [
  { name: 'Sara', title: 'Manager Proiect', image: imagePath + 'MP.png' },
  { name: 'Alex', title: 'Manager IT', image: imagePath + 'IT.png' },
  { name: 'Consta', title: 'Manager Logistica', image: imagePath + 'Logistica-1.png' },
  { name: 'Miru', title: 'Manager Design', image: imagePath + 'Design.png' },
  { name: 'Teo', title: 'Manager Fundraising', image: imagePath + 'FR.png' },
  { name: 'Eve', title: 'Manager HR', image: imagePath + 'HR.png' },
  { name: 'Tania', title: 'Manager PR', image: imagePath + 'PR.png' },
  { name: 'Esme', title: 'Manager Multimedia', image: imagePath + 'MM.png' },
  { name: 'Niță Eduard', title: 'Mentor', image: imagePath + 'Mentor1.png' },
  { name: 'Tudor Diana', title: 'Mentor', image: imagePath + 'Mentor2.png' },
  { name: 'Popescu Alexandra', title: 'Mentor', image: imagePath + 'Mentor3.png' },
  { name: 'Dana', title: 'Vicepresedinte Responsabil', image: imagePath + 'Vice.png' },
];

const Carousel = () => {
  return (
    <Container maxW={{ base: '90vw', sm: '90vw', xl: '70vw' }} mb={['2vh', '10vh']}>
      <Slider
        swipeToSlide={true}
        slidesToShow={useBreakpointValue({ base: 3, sm: 3, lg: 5 })}
        centerMode={true}
        centerPadding="0px"
        autoplay={true}
        autoplaySpeed={3000}
        nextArrow={<ArrowButton type="right" src={Next} />}
        prevArrow={<ArrowButton type="left" src={Prev} />}
      >
        {teamMembers.map((member, index) => (
          <CarouselCard key={index} image={member.image} name={member.name} title={member.title} />
        ))}
      </Slider>
    </Container>
  );
};

export default Carousel;
