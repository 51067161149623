import { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  Text,
  Input,
  HStack,
} from '@chakra-ui/react';
import ProfileService from './services/ProfileService';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import matrix from '../../common/assets/bgMatrixProfile.svg';
import border from '../../common/assets/chenarProfil.png';

const CompleteLoginInfo: React.FC = () => {
  const shirtSizes = ['S', 'M', 'L', 'XL'];
  const pronouns = ['-', 'He/Him', 'She/Her', 'They/Them', 'Other'];

  const dietOptions = [
    { value: 'VEGAN', label: 'Vegan' },
    { value: 'VEGETARIAN', label: 'Vegetarian' },
    { value: 'NON_VEGAN', label: 'Non Vegan' },
  ];

  const [pronoun, setPronoun] = useState('-');
  const [shirtSize, setShirtSize] = useState('M');
  const [diet, setDiet] = useState('NON_VEGAN');
  const [allergies, setAllergies] = useState('');
  const [cv, setCv] = useState<File | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  const handleSave = async () => {
    const token = auth.user?.access_token ?? '';
    try {
      setIsSaving(true);
      await ProfileService.updateUser(token, shirtSize, diet, allergies, cv, pronoun);

      window.location.href = '/profile';
    } catch (error) {
      setIsSaving(false);
      navigate('/');
    }
  };

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      color="white"
      textAlign="center"
      bgImage={{ base: 'none', md: matrix }}
      bgSize="cover"
      bgRepeat="no-repeat"
      bgPosition="center"
      p={{ base: '5rem', md: '0rem' }}
    >
      <Text fontSize="4xl" mb="2rem" fontWeight="bold">
        Completează-ți informațiile
      </Text>
      <Box
        bgImage={{ base: '', md: border, lg: border }}
        bgSize="100% 100%"
        bgPosition="unset"
        bgRepeat="no-repeat"
        color="white"
        border={{ base: '2px ', md: 'none' }}
        borderColor={'rgb(32, 177, 130)'}
        borderRadius={'5px'}
        px={{ base: '3rem', md: '3rem', lg: '3rem', xl: '3rem' }}
        py={{ base: '3rem', md: '0.5rem', lg: '3rem', xl: '2rem', '2xl': '5rem' }}
        w={{ base: '80vw', md: '95vw', lg: '85vw', xl: '95vh' }}
        h={{ lower_sm: '95vh', sm: '70vh', md: '95vh', lg: '85vh', xl: '95vh' }}
      >
        <HStack
          display={{ base: 'flex', md: 'flex' }}
          flexDirection={{ base: 'column', md: 'row' }}
          mb={{ base: '1.5rem', md: '0.5rem' }}
        >
          <FormControl mb={{ base: '1.5rem', md: '0.5rem' }} display="flex" alignItems="center">
            <FormLabel color="red">{'*'}</FormLabel>
            <FormLabel mb="0" mr="1rem">
              Pronume:
            </FormLabel>
            <Select
              bg="rgba(32, 177, 130, 1)"
              color="white"
              borderRadius="30px"
              border="none"
              width="150px"
              value={pronoun}
              onChange={e => setPronoun(e.target.value)}
              sx={{
                option: {
                  backgroundColor: 'rgba(32, 177, 130, 0.1)', // Set background color for all option elements
                  border: '2px solid rgba(32, 177, 130, 1)', // Set border for all option elements
                  color: 'white',
                },
              }}
            >
              {pronouns.map(size => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>
          </FormControl>
        </HStack>

        <FormControl mb={{ base: '1.5rem', md: '0.5rem' }} display="flex" alignItems="center">
          <FormLabel mb="0" mr="1rem">
            Mărime tricou:
          </FormLabel>
          <Select
            bg="rgba(32, 177, 130, 1)"
            color="white"
            borderRadius="30px"
            border="none"
            width="100px"
            value={shirtSize}
            onChange={e => setShirtSize(e.target.value)}
            sx={{
              option: {
                backgroundColor: 'rgba(32, 177, 130, 0.1)', // Set background color for all option elements
                border: '2px solid rgba(32, 177, 130, 1)', // Set border for all option elements
                color: 'white',
              },
            }}
          >
            {shirtSizes.map(size => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </Select>
        </FormControl>

        {/* CV Upload */}
        <Box
          display="flex"
          alignItems="center"
          mb={{ base: '1.5rem', md: '0.5rem' }}
          flexDirection="column"
          placeItems="start"
        >
          <FormLabel mb="1" flex="1">
            {' '}
            <HStack>
              <FormLabel color="red">{'*'}</FormLabel>
              <Text>CV:</Text>
              <Text color="gray">(only .pdf)</Text>
            </HStack>
          </FormLabel>
          <Input
            type="file"
            accept=".pdf"
            maxWidth="fit-content"
            height="10vh"
            bg="rgba(32, 177, 130, 0.1)"
            borderWidth="2px"
            borderColor="rgba(32, 177, 130, 1)"
            onChange={e => setCv(e.target.files?.[0] || null)}
          />
        </Box>

        <FormControl mb={{ base: '1.5rem', md: '0.5rem' }}>
          <FormLabel>Regim alimentar:</FormLabel>
          <Select
            // size="sm"
            width={{ md: '40%' }}
            bg="rgba(32, 177, 130, 1)"
            color="white"
            borderRadius="30px"
            border="none"
            value={diet}
            onChange={e => setDiet(e.target.value)}
            sx={{
              option: {
                backgroundColor: 'rgba(32, 177, 130, 0.1)', // Set background color for all option elements
                border: '2px solid rgba(32, 177, 130, 1)', // Set border for all option elements
                color: 'white',
              },
            }}
          >
            {dietOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl mb={{ base: '1.5rem', md: '0.5rem' }}>
          <FormLabel>Alte preferințe alimentare/ alergii:</FormLabel>
          <Textarea
            placeholder="Optional"
            borderRadius="20px"
            bg="rgba(32, 177, 130, 0.1)"
            borderWidth="2px"
            borderColor="rgba(32, 177, 130, 1)"
            onChange={e => setAllergies(e.target.value)}
          />
        </FormControl>
        <Button
          bg="rgba(32, 177, 130, 0.7)"
          borderWidth="2px"
          borderColor="rgba(32, 177, 130, 1)"
          mt={{ lg: '2rem', xl: '1rem' }}
          onClick={handleSave}
          isLoading={isSaving}
          disabled={pronoun === '-' || cv === null}
        >
          Salvează
        </Button>
      </Box>
    </Box>
  );
};

export default CompleteLoginInfo;
