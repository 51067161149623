import { VStack } from '@chakra-ui/react';
import Countdown from './components/Countdown';
import Landing from './components/Landing';
import FetchId from 'src/common/services/IdService';

const Home = () => {
  const eventDate = process.env.REACT_APP_EVENT_DATE
    ? new Date(process.env.REACT_APP_EVENT_DATE)
    : new Date('2025-04-04T10:00:00');

  return (
    <VStack spacing={['10vh', '17.5vh', '25vh']} mt="52px" id={FetchId.Home}>
      <Landing />
      <Countdown targetDate={eventDate} />
    </VStack>
  );
};

export default Home;
