import { Image, Flex, Link, Box, Text } from '@chakra-ui/react';
import React from 'react';
import Facebook from '../../assets/facebook.svg';
import Instagram from '../../assets/insta.svg';
import LinkedIn from '../../assets/linkedin.svg';
import Twitch from '../../assets/twitch.svg';
import Youtube from '../../assets/Youtube.svg';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <Box backgroundColor="#000E16">
      <Flex
        w="100%"
        px="6"
        py="5"
        align="center"
        justify="center"
        gap={{ base: '5vw', md: '64px' }}
      >
        <Link href="https://www.facebook.com/LsacBucuresti/" isExternal>
          <Image src={Facebook} boxSize={{ base: '40px', md: '46px' }} />
        </Link>
        <Link href=" https://www.twitch.tv/lsac_bucuresti?lang=ro" isExternal>
          <Image src={Twitch} boxSize={{ base: '40px', md: '46px' }} />
        </Link>
        <Link href="https://www.youtube.com/user/LSACBucuresti" isExternal>
          <Image src={Youtube} boxSize={{ base: '40px', md: '46px' }} />
        </Link>
        <Link href="https://www.instagram.com/hackitall.lsacbucuresti/" isExternal>
          <Image src={Instagram} boxSize={{ base: '40px', md: '46px' }} />
        </Link>
        <Link href="https://www.linkedin.com/company/lsac-bucuresti/" isExternal>
          <Image src={LinkedIn} boxSize={{ base: '40px', md: '46px' }} />
        </Link>
      </Flex>

      <Text color="#919191" size="2rem" align="center">
        Copyright © LSAC București {year}
      </Text>
    </Box>
  );
};
export default Footer;
