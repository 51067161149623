import { Text, GridItem } from '@chakra-ui/react';
import React from 'react';
import theme from 'src/common/styles/CustomTheme';

interface EventCardProps {
  title: string;
  description: string | React.ReactNode;
  background: string;
  [key: string]: any;
}

const EventCard: React.FC<EventCardProps> = ({ title, description, background, ...props }) => {
  return (
    <GridItem
      py={['7vw', '7vw', '2.5vw']}
      px={['7vw', '7vw', '3.5vw']}
      width={['60vw', '60vw', '27.5vw']}
      height={['51.3vw', '51.3vw', '23.5vw']}
      bgImage={background}
      backgroundSize="contain"
      backgroundRepeat="no-repeat"
      {...props}
    >
      <Text
        fontSize={['3.4vw', '2.5vw', '1.5vw']}
        mb="2%"
        fontFamily={theme.fonts.eventCard}
        color="#20B182"
      >
        <strong>{title}</strong>
        <br />
      </Text>
      <Text
        fontSize={['3vw', '2.5vw', '1.2vw']}
        fontFamily={theme.fonts.eventCard}
        color="#C7ECE0"
        maxH="98%"
      >
        {description}
      </Text>
    </GridItem>
  );
};

export default EventCard;
