import {
  VStack,
  Text,
  Box,
  Collapse,
  Button,
  HStack,
  Image,
  Link,
  Divider,
} from '@chakra-ui/react';

import Open from './assets/Open.svg';
import Close from './assets/Closed.svg';

import FetchId from 'src/common/services/IdService';
import { useState } from 'react';
import theme from 'src/common/styles/CustomTheme';

const categories = ['Hackathon', 'Gamejam', 'Side Challenge', 'Diverse'];

const questions = [
  {
    question: 'Cine este juriul?',
    answer: 'Juriul este format din reprezentanți ai sponsorului principal.',
    category: 'Diverse',
  },
  // {
  //   question: 'Ce este o firmă recruiter?',
  //   answer:
  //     'Firmele recruiter au acces la CV-urile participanților aleși în urma filtrării acestora de către sponsorul principal în cadrul hackthonului pentru care au aplicat. Aceste companii nu pot influența jurizarea proiectelor finale.',
  //   category: 'Diverse',
  // },
  {
    question: 'Cine selectează echipele?',
    answer:
      'Echipele participante sunt alese de către sponsorul competiției pe baza CV-urilor participanților.',
    category: 'Diverse',
  },
  {
    question: 'Trebuie să plătesc o taxă de înregistrare pentru a participa?',
    answer:
      'Nu, înregistrarea este gratuită pentru a oferi șansa cât mai multor tineri de a se iniția în domeniul programării.',
    category: 'Diverse',
  },
  {
    question: 'Trebuie să folosim anumite limbaje de programare?',
    answer:
      'Dorim ca participanții să își pună în evidență atuurile folosind un limbaj de programare cunoscut de aceștia și cu care se simt confortabil. De aceea, nu există restricții în acest sens.',
    category: 'Diverse',
  },
  {
    question: 'Cât durează concursul?',
    answer:
      'Proba de hackathon se desfășoară pe parcursul a 24 de ore, la finalul cărora echipele își vor prezenta proiectele, urmând ca juriul să decidă câștigătorii.',
    category: 'Hackathon',
  },
  {
    question: 'Cât durează concursul?',
    answer:
      'Proba de GameJam se desfășoară pe parcursul a 48 de ore, 24 de ore online, 24 de ore fizic, la finalul cărora echipele își vor prezenta proiectele, urmând ca juriul să decidă câștigătorii.',
    category: 'Gamejam',
  },
  {
    question: 'Când va începe competiția?',
    answer: 'Competiția va începe sâmbătă, 5 aprilie, la ora 10:00.',
    category: 'Hackathon',
  },
  {
    question: 'Când va începe competiția?',
    answer:
      'Competiția va începe vineri, 4 aprilie, în format online și sâmbătă, 5 aprilie, la ora 10:00 in format fizic.',
    category: 'Gamejam',
  },
  {
    question: 'Care este structura unei echipe?',
    answer: 'Echipele vor fi formate din 3 studenți care se vor înscrie împreună.',
    category: 'Hackathon',
  },
  {
    question: 'Care este structura unei echipe?',
    answer: 'Echipele vor fi formate din 3 studenți care se vor înscrie împreună.',
    category: 'Gamejam',
  },
  {
    question: 'Mă pot înscrie la mai multe probe de hackathon?',
    answer: 'Sigur, dar în final vei fi selectat doar pentru una dintre ele.',
    category: 'Hackathon',
  },
  {
    question: 'Pot participa dacă nu sunt de la Facultatea de Automatică și Calculatoare?',
    answer: 'Da, poți! Singura restricție aplicată este ca toți membrii să fie studenți.',
    category: 'Gamejam',
  },
  {
    question: 'Pot participa dacă nu sunt de la Facultatea de Automatică și Calculatoare?',
    answer: 'Da, poți! Singura restricție aplicată este ca toți membrii să fie studenți.',
    category: 'Hackathon',
  },
  {
    question:
      'Pot să mă înscriu la Game Jam chiar dacă nu am experiență în zona de game development?',
    answer:
      'Da, vă încurajăm să vă înscrieți! Această experiență vă oferă noi oportunități și puteți învăța să creați o aplicație de la zero, sub presiunea timpului.',
    category: 'Gamejam',
  },
  {
    question: 'Pot să mă înscriu la HackITall chiar dacă ăsta este primul meu hackathon?',
    answer:
      'Da, vă încurajăm să vă înscrieți! Această experiență vă oferă noi oportunități și puteți învăța să creați o aplicație de la zero, sub presiunea timpului.',
    category: 'Hackathon',
  },
  {
    question: 'Vor exista sesiuni de mentorat?',
    answer:
      'Da, angajați de la companiile partenere vor fi prezenți și se vor organiza sesiuni de mentorat, special pentru a rezolva orice neclaritate și a oferi sfaturi utile în dezvoltarea aplicației.',
    category: 'Diverse',
  },
  {
    question: 'Va exista o probă preliminară?',
    answer: 'Echipele vor fi alese de baza CV-ului. Nu există o probă de departajare.',
    category: 'Diverse',
  },
  {
    question: 'La cine pot apela în cazul în care am probleme?',
    answer:
      'Orice problemă poate fi semnalată membrilor echipei de organizare. În plus, în timpul competiției, te poți adresa voluntarilor evenimentului.',
    category: 'Diverse',
  },
  {
    question: 'Dacă mă înscriu la competițiile mai multor companii pot schimba preferințele?',
    answer:
      'Da, până la încheierea  perioadei de înscrieri, preferințele pentru participarea la hackathonul unei anumite companii se pot schimba din pagina contului cu care s-a făcut înregistrarea.',
    category: 'Hackathon',
  },
  {
    question: 'Pot să mă înscriu de unul singur sau cu o echipă incompletă?',
    answer:
      'Nu, pentru a avea o înscriere validă în cadrul competiției este necesar ca echipa ta să fie formată din 3 membri.',
    category: 'Gamejam',
  },
  {
    question: 'Pot să mă înscriu de unul singur sau cu o echipă incompletă?',
    answer:
      'Nu, pentru a avea o înscriere validă în cadrul competiției este necesar ca echipa ta să fie formată din 3 membri.',
    category: 'Hackathon',
  },
  {
    question: 'Care este modul de desfășurare al evenimentului?',
    answer: 'Proba de hackathon va fi susținută în format fizic.',
    category: 'Hackathon',
  },
  {
    question: 'Care este modul de desfășurare al evenimentului?',
    answer: 'Proba Gamejam va fi susținută în format fizic dar și online.',
    category: 'Gamejam',
  },
  {
    question: 'Care sunt documentele necesare pentru înscrierea în cadrul competiției?',
    answer: 'Pentru a participa va trebui să încarci CV-ul pe site.',
    category: 'Diverse',
  },
  {
    question: 'Mă pot înscrie și la Hackathon și la Game Jam?',
    answer: 'Sigur, dar în final vei fi selectat doar pentru una dintre ele.',
    category: 'Gamejam',
  },
  {
    question: 'Cât durează concursul?',
    answer: 'Proba de Side Challenge se desfășoară pe parcursul a 10 ore.',
    category: 'Side Challenge',
  },
  {
    question: 'Când va începe challenge-ul?',
    answer: 'Challenge-ul va începe sâmbătă, 29 martie, la ora 10:00.',
    category: 'Side Challenge',
  },
  {
    question: 'Mă pot înscrie și la probele hackathonului și la side challenge?',
    answer: 'Sigur, competițiile au loc în zile diferite.',
    category: 'Side Challenge',
  },
  {
    question: 'Care este structura unei echipe?',
    answer: 'Participarea la acest challenge este fie individuală, fie în echipă de doi membrii.',
    category: 'Side Challenge',
  },
  {
    question: 'Pot participa dacă nu sunt de la Facultatea de Automatică și Calculatoare?',
    answer: 'Da, poți! Singura restricție aplicată este să fii student.',
    category: 'Side Challenge',
  },
  {
    question: 'Care este modul de desfășurare al evenimentului?',
    answer: 'Proba de side challenge va fi susținută în format fizic.',
    category: 'Side Challenge',
  },
  {
    question: 'Unde se va desfășura competiția de side challenge?',
    answer: 'Competiția se va desfășura la sediul partenerului nostru, Veridion.',
    category: 'Side Challenge',
  },
];

const FAQ = () => {
  const [selectedCategory, setSelectedCategory] = useState('Hackathon');
  const [openIndex, setOpenIndex] = useState(-1);
  const filteredQuestions = questions.filter(q => q.category === selectedCategory);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? -1 : index);
  };

  return (
    <VStack spacing={['3vh', '3vh']} mt="5vh" id={FetchId.FAQ}>
      <Text
        width={{ base: '80vw', md: '70vw', lg: '60vw', xl: '50vw' }}
        textAlign="left"
        fontFamily={theme.fonts.sectionTitle}
        fontSize={{ base: '8vw', md: '6vw', lg: '5vw', xl: '4vw' }}
        color="DEDEDE"
        lineHeight={['10vw', '8vw', '82.58px']}
      >
        FAQ
      </Text>
      <HStack
        width={{ base: '80vw', md: '70vw', lg: '60vw', xl: '50vw' }}
        spacing={['2.3vw', '25px']}
        justify="flex-start"
        wrap="wrap"
      >
        {categories.map(cat => {
          const isSelected = selectedCategory === cat;
          return (
            <Button
              key={cat}
              height="fit-content"
              onClick={() => {
                if (cat != selectedCategory) setOpenIndex(-1);
                setSelectedCategory(cat);
              }}
              variant="unstyled"
              fontFamily={theme.fonts.faq}
              fontSize={{ base: '4vw', md: '3vw', lg: '2w', xl: '1.5vw' }}
              fontWeight="bold"
              color="#C7ECE0"
              _focus={{ boxShadow: 'none' }}
              borderBottom={isSelected ? '2px solid #C7ECE0' : '2px solid transparent'}
              borderRadius="0"
              paddingBottom="0"
              data-selected={selectedCategory === cat ? 'true' : 'false'}
            >
              {cat}
            </Button>
          );
        })}
      </HStack>
      <VStack spacing="1vh" width="100vw" flex={0} mt="0vh" mb="5vh">
        <Divider
          width={{ base: '80vw', md: '70vw', lg: '60vw', xl: '50vw' }}
          borderColor="#64C8A8"
          borderWidth="1.3px"
        ></Divider>
        {filteredQuestions.map((q, index) => (
          <Box key={index} width={{ base: '80vw', md: '70vw', lg: '60vw', xl: '50vw' }}>
            <HStack onClick={() => handleToggle(index)} width="100%">
              <Image src={openIndex === index ? Open : Close} boxSize={['8px', '10px', '14px']} />
              <Text
                textIndent="2.5px"
                fontFamily={theme.fonts.faq}
                fontSize={{ base: '3vw', md: '2.5vw', lg: '2vw', xl: '1.1vw' }}
                fontWeight="600"
                color="#DEDEDE"
              >
                {q.question}
              </Text>
            </HStack>
            <Collapse in={openIndex === index} animateOpacity>
              <Text
                my="1vh"
                fontFamily={theme.fonts.faq}
                fontSize={{ base: '3vw', md: '2.5vw', lg: '2vw', xl: '1.1vw' }}
                fontWeight="600"
                position="relative"
                width="100%"
                pr="5%"
                whiteSpace="normal"
                wordBreak={'break-word'}
                left="2.5vw"
                textAlign="left"
                color="#ABABAB"
                maxHeight="15vh"
                overflowY="auto"
              >
                {q.answer}
              </Text>
            </Collapse>
            <Divider
              width="100%"
              borderColor="#64C8A8"
              borderWidth="1.3px"
              margin="1vh 0"
            ></Divider>
          </Box>
        ))}
        <Text
          width={{ base: '80vw', md: '70vw', lg: '60vw', xl: '50vw' }}
          textAlign="left"
          fontFamily={theme.fonts.faq}
          fontSize={{ base: '3.5vw', md: '2.5vw', lg: '2vw', xl: '1.25vw' }}
          fontWeight="bold"
          color="#C7ECE0"
          lineHeight={['6vw', '43.88px']}
        >
          Te invităm să consulți regulamentul{' '}
          <Link href={process.env.REACT_APP_RULES_URL} color=" #20B182">
            aici
          </Link>
          !
        </Text>
      </VStack>
    </VStack>
  );
};

export default FAQ;
