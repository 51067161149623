import axios from 'axios';
import { PageSchema } from 'admin_lsac';
import * as Yup from 'yup';

export const pages: PageSchema[] = [
  {
    name: 'Teams',
    actions: [
      {
        name: 'Export to CSV',
        fields: [],
        onSubmit: async () => {
          const token = localStorage.getItem('access_token');
          const Headers = {
            Authorization: `Bearer ${token}`,
          };
          await axios
            .get(`${process.env.REACT_APP_API_URL}team/export`, {
              headers: Headers,
              responseType: 'blob',
            })
            .then(response => {
              // For the response to automatically get downloaded we must simulate the user clicking on a link

              const blob = new Blob([response.data], { type: 'text/csv' });

              // Create a link element
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.setAttribute('download', `$teams.csv`); // Set download filename
              document.body.appendChild(link);

              // Simulate click to trigger download
              link.click();

              // Cleanup
              document.body.removeChild(link);
            })
            .catch(error => {
              console.error('Error downloading file:', error);
            });
        },
      },
    ],
    rowActions: [],
    getRequest: async () => {
      try {
        const token = localStorage.getItem('access_token');
        const Headers = {
          Authorization: `Bearer ${token}`,
        };
        const res = await axios.get(process.env.REACT_APP_API_URL + 'team/allTeams', {
          headers: Headers,
        });

        return res.data;
      } catch (e) {
        console.log(e);
      }
    },
    tableFields: [
      {
        name: 'Team Name',
        access: (obj: any) => obj.name,
      },
      {
        name: 'Capitan',
        access: (obj: any) => obj.members[0].familyName + ' ' + obj.members[0].givenName,
      },
      {
        name: 'Location',
        access: (obj: any) => obj.location,
      },
      {
        name: 'Member1',
        access: (obj: any) =>
          obj.members[0] ? obj.members[0].familyName + ' ' + obj.members[0].givenName : 'None',
      },
      {
        name: 'Member2',
        access: (obj: any) =>
          obj.members[1] ? obj.members[1].familyName + ' ' + obj.members[1].givenName : 'None',
      },
      {
        name: 'Member3',
        access: (obj: any) =>
          obj.members[2] ? obj.members[2].familyName + ' ' + obj.members[2].givenName : 'None',
      },
      {
        name: 'Option 1',
        access: (obj: any) => (obj.teamPreferences[0] ? obj.teamPreferences[0].event.name : 'None'),
      },
      {
        name: 'Option 2',
        access: (obj: any) => (obj.teamPreferences[1] ? obj.teamPreferences[1].event.name : 'None'),
      },
      {
        name: 'Option 3',
        access: (obj: any) => (obj.teamPreferences[2] ? obj.teamPreferences[2].event.name : 'None'),
      },
      {
        name: 'Option 4',
        access: (obj: any) => (obj.teamPreferences[3] ? obj.teamPreferences[3].event.name : 'None'),
      },
      {
        name: 'Status',
        access: (obj: any) => obj.status,
      },
      {
        name: 'location',
        access: (obj: any) => (obj.location ? obj.location : 'None'),
      },
      {
        name: 'Participates in',
        access: (obj: any) => (obj.participatesIn ? obj.participatesIn : 'None'),
      },
    ],
  },
  {
    name: 'Users',
    actions: [],
    rowActions: [],
    getRequest: async () => {
      try {
        const token = localStorage.getItem('access_token');
        const Headers = {
          Authorization: `Bearer ${token}`,
        };
        const res = await axios.get(process.env.REACT_APP_API_URL + 'user/allUsers', {
          headers: Headers,
        });

        return res.data;
      } catch (e) {
        console.log(e);
      }
    },
    tableFields: [
      {
        name: 'UserID',
        access: (obj: any) => obj.id,
      },
      {
        name: 'User Name',
        access: (obj: any) => obj.name,
      },
      {
        name: 'Family Name',
        access: (obj: any) => obj.familyName,
      },
      {
        name: 'Given Name',
        access: (obj: any) => obj.givenName,
      },
      {
        name: 'Phone Number',
        access: (obj: any) => (obj.phoneNumber ? obj.phoneNumber : 'None'),
      },
      {
        name: 'University',
        access: (obj: any) => (obj.studentUniversity ? obj.studentUniversity : 'None'),
      },
      {
        name: 'Faculty',
        access: (obj: any) => (obj.studentFaculty ? obj.studentUniversity : 'None'),
      },
      {
        name: 'Study Year',
        access: (obj: any) => (obj.studentYear ? obj.studentYear : 'None'),
      },
      {
        name: 'Student Email',
        access: (obj: any) => (obj.studentEmail ? obj.studentEmail : 'None'),
      },
      {
        name: 'Pronoumns',
        access: (obj: any) => (obj.pronouns ? obj.pronouns : 'None'),
      },
      {
        name: 'Profile completed',
        access: (obj: any) => (obj.profileCompleted ? 'Yes' : 'No'),
      },
      {
        name: 'Shirt Size',
        access: (obj: any) => (obj.shirtSize ? obj.shirtSize : 'None'),
      },
      {
        name: 'Diet',
        access: (obj: any) => (obj.diet ? obj.diet : 'None'),
      },
      {
        name: 'Allergies',
        access: (obj: any) => (obj.allergies ? obj.allergies : 'None'),
      },
      {
        name: 'TeamId',
        access: (obj: any) => (obj.teamId ? obj.teamId : 'None'),
      },
      {
        name: 'Role',
        access: (obj: any) => obj.role,
      },
    ],
  },
  {
    name: 'Events',
    actions: [
      {
        name: 'Get all CVs',
        fields: [
          {
            name: 'eventId',
            label: 'eventId',
            type: 'string',
            validationSchema: Yup.string().required(),
          },
          {
            name: 'zipFileName',
            label: 'zipFileName',
            type: 'string',
            validationSchema: Yup.string().required(),
          },
        ],
        onSubmit: async formValues => {
          const token = localStorage.getItem('access_token');
          console.log('Get request for cvs');
          const Headers = {
            Authorization: `Bearer ${token}`,
          };
          await axios
            .get(`${process.env.REACT_APP_API_URL}cv/all/${formValues.eventId}`, {
              headers: Headers,
              responseType: 'blob',
            })
            .then(response => {
              // For the response to automatically get downloaded we must simulate the user clicking on a link

              // Create a Blob from the response data
              const blob = new Blob([response.data], { type: 'application/zip' });

              // Create a link element
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.setAttribute('download', `${formValues.zipFileName}.zip`); // Set download filename
              document.body.appendChild(link);

              // Simulate click to trigger download
              link.click();

              // Cleanup
              document.body.removeChild(link);
            })
            .catch(error => {
              console.error('Error downloading file:', error);
            });
        },
      },
    ],
    rowActions: [],
    getRequest: async () => {
      try {
        const token = localStorage.getItem('access_token');
        const Headers = {
          Authorization: `Bearer ${token}`,
        };
        const res = await axios.get(process.env.REACT_APP_API_URL + 'event', {
          headers: Headers,
        });

        return res.data;
      } catch (e) {
        console.log(e);
      }
    },
    tableFields: [
      {
        name: 'event Id',
        access: (obj: any) => obj.id,
      },
      {
        name: 'event name',
        access: (obj: any) => obj.name,
      },
    ],
  },
];
