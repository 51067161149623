import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`
    @font-face {
        font-family: 'Mukta';
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
      font-family: 'b Biger Over';
      src: url('./fonts/bBigerOver.woff') format('woff') url('./fonts/bBigerOver.woff2') format('woff2');
      font-style: normal;
      font-weight: normal;  
      font-display: swap;
    }

    @font-face {
      font-family: 'Cocogoose';
      src: url('./fonts/Cocogoose_trial.woff2') format('woff2');
      font-style: normal;
      font-weight: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Montserrat';
      src: url('./fonts/Montserrat-Regular.woff2') format('woff2'),
           url('./fonts/Montserrat-Regular.woff') format('woff');
      font-weight: 400;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Montserrat';
      src: url('./fonts/Montserrat-Bold.woff2') format('woff2'),
           url('./fonts/Montserrat-Bold.woff') format('woff');
      font-weight: 700;
      font-style: normal;
      font-display: swap;
    }
      `}
  />
);

export default Fonts;
