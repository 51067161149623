import React, { useEffect, useState } from 'react';
import { Modal, ModalOverlay } from '@chakra-ui/react';
import ProfileService from '../services/ProfileService'; // Update the import path as necessary
import { useAuth } from 'react-oidc-context';

import ModalTeam from '../components/ModalTeam';

interface CreateTeamModalProps {
  isOpen: boolean;
  onClose: () => void;
  onTeamCreated: (teamName: string, githubPage: string, rankings: number[]) => void; // Updated to accept rankings
}

const CreateTeamModal: React.FC<CreateTeamModalProps> = ({ isOpen, onClose, onTeamCreated }) => {
  const [teamName, setTeamName] = useState('');
  const [githubPage, setGithubPage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [rankings, setRankings] = useState<number[]>([]); // State for rankings
  const auth = useAuth();

  useEffect(() => {
    setLoading(auth.isLoading);
  }, [auth.isLoading]);

  useEffect(() => {
    if (isOpen) {
      const fetchProbes = async () => {
        try {
          const token = auth.user?.access_token ?? undefined;
          if (token) {
            const fetchedEvents = await ProfileService.fetchEvents(token);
            setRankings(Array(fetchedEvents.length).fill(0));
          }
        } catch (error) {
          setError('Failed to fetch events.');
        }
      };
      fetchProbes();
    }
  }, [isOpen, auth.user?.access_token]);

  const handleSave = async () => {
    setLoading(true);
    setError('');

    const token = auth.user?.access_token ?? undefined;

    if (!token) {
      setLoading(false);
      setError('Failed to join team. No token found.');
      return;
    }

    if (!teamName) {
      setLoading(false);
      setError('Enter the name of the team.');
      setLoading(false);
      return;
    }

    try {
      const isProfileComplete = await ProfileService.isProfileCompleted(token);
      if (!isProfileComplete) {
        setError('Profile not completed, you cannot create a team.');
        setLoading(false);
        return;
      }
      await ProfileService.createTeam(token, teamName, githubPage, rankings); // Send rankings
      onTeamCreated(teamName, githubPage, rankings);
      onClose();
    } catch (error) {
      setError('Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" scrollBehavior="inside">
      <ModalOverlay />
      <ModalTeam
        handleSave={handleSave}
        loading={loading}
        rankings={rankings}
        setRankings={setRankings}
        setTeamName={setTeamName}
        type="create"
        error={error}
        setError={setError}
      />
    </Modal>
  );
};

export default CreateTeamModal;
