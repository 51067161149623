import React, { useEffect, useState } from 'react';
import { Modal, ModalOverlay } from '@chakra-ui/react';
import ProfileService from '../services/ProfileService';
import { useAuth } from 'react-oidc-context';
import ModalTeam from '../components/ModalTeam';
import MessageModal from './messageModal';

const ManageTeamModal: React.FC<{
  isOpen: boolean;
  type: 'manage' | 'create' | 'view';
  onClose: () => void;
}> = ({ isOpen, type, onClose }) => {
  const [teamName, setTeamName] = useState('');
  const [githubPage, setGithubPage] = useState('');
  const [rankings, setRankings] = useState<number[]>([]);
  const [teamMembers, setTeamMembers] = useState<{ id: string; name: string }[]>([]);
  const [captainId, setCaptainId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [kickModalOpen, setKickModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState<{ id: string; name: string } | null>(null);
  const auth = useAuth();

  useEffect(() => {
    const fetchTeamData = async () => {
      const token = auth.user?.access_token ?? '';
      try {
        setLoading(true);
        const teamData = await ProfileService.fetchTeamData(token);
        const fetchedEvents = await ProfileService.fetchEvents(token);
        setTeamName(teamData.name);
        setGithubPage(teamData.githubUrl);
        setTeamMembers(teamData.members);
        setRankings(
          fetchedEvents.map((event: { id: string }) =>
            (teamData.teamPreferences as Array<{ eventId: string }>)
              .map(p => p.eventId)
              .includes(event.id)
              ? (teamData.teamPreferences as Array<{ eventId: string }>).findIndex(
                  p => p.eventId == event.id,
                ) + 1
              : 0,
          ),
        );

        setCaptainId(teamData.capitanId);

        const currentUserId = auth.user?.profile.sub;
        const updateTeamMembers =
          currentUserId === teamData.capitanId
            ? teamData.members.filter((member: { id: string }) => member.id !== currentUserId)
            : teamData.members;
        setTeamMembers(updateTeamMembers);
      } catch (error) {
        setError('Failed to fetch team data.');
      } finally {
        setLoading(false);
      }
    };

    if (isOpen && auth.user?.access_token) {
      fetchTeamData();
    }
  }, [isOpen, auth.user?.access_token]);

  const handleSave = async () => {
    setLoading(true);
    setError('');

    const token = auth.user?.access_token ?? undefined;
    if (!token) {
      setLoading(false);
      setError('Failed to update. No token found.');
      return;
    }
    if (!teamName) {
      setLoading(false);
      setError('Please enter the name of the team.');
      return;
    }

    try {
      await ProfileService.updateTeam(token, teamName, githubPage, rankings);
      onClose();
    } catch (error) {
      setError('Failed to update team. Please try again.');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleKickMember = async () => {
    const token = auth.user?.access_token ?? '';
    if (!selectedMember) return;

    try {
      await ProfileService.kickMember(token, selectedMember.id);
      setTeamMembers(prev => prev.filter(member => member.id !== selectedMember.id));
      setKickModalOpen(false);
    } catch (error) {
      setError('Failed to kick member.');
    }
  };

  const openKickModal = (member: { id: string; name: string }) => {
    setSelectedMember(member);
    setKickModalOpen(true);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" scrollBehavior="inside">
      <ModalOverlay />
      <ModalTeam
        captainId={captainId}
        handleSave={handleSave}
        loading={loading}
        openKickModal={openKickModal}
        rankings={rankings}
        setRankings={setRankings}
        setTeamName={setTeamName}
        type={type}
        teamMembers={teamMembers}
        teamName={teamName}
        error={error}
        setError={setError}
      />

      <MessageModal
        isOpen={kickModalOpen}
        onClose={() => setKickModalOpen(false)}
        onConfirm={handleKickMember}
        userName={selectedMember?.name || ''}
        type="kick"
      />
    </Modal>
  );
};

export default ManageTeamModal;
