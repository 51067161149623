import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import feedbackCard from './assets/feedbackCard.svg';
import theme from 'src/common/styles/CustomTheme';

interface FeedbackCardProps {
  author: string;
  description: string;
}

const FeedbackCard: React.FC<FeedbackCardProps> = ({ author, description }) => {
  return (
    <Box
      position="relative"
      w="100%"
      pl="10%"
      pt="20%"
      pr="10%"
      aspectRatio="1"
      textAlign="left"
      backgroundImage={feedbackCard}
      backgroundSize="contain"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
    >
      <Text
        fontFamily={theme.fonts.feedbackCard}
        fontWeight="700"
        maxH="70%"
        overflowY="scroll"
        fontSize={{ base: '3.5vw', lg: '2vw', xl: '1vw' }}
        color="#FFFFFF"
      >
        {description}
      </Text>
      <Text
        fontFamily={theme.fonts.feedbackCard}
        fontWeight="700"
        color="#20B182"
        position={'absolute'}
        bottom="15%"
        fontSize={{ base: '3.5vw', lg: '2vw', xl: '1vw' }}
        right="10%"
      >
        {author}
      </Text>
    </Box>
  );
};

export default FeedbackCard;
