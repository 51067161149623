const FetchId = {
  Home: 'HackITAll',

  Events: 'Events',

  Sponsors: 'Sponsors',

  FAQ: 'FAQ',

  Contact: 'Contact',
};

export default FetchId;
