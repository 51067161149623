import React, { useEffect, useState } from 'react';
import { Text, VStack, HStack, Box, useBreakpointValue } from '@chakra-ui/react';
import { CircularProgress } from '@chakra-ui/react';
import theme from 'src/common/styles/CustomTheme';

interface CountdownProps {
  targetDate: Date;
}

interface CountdownTime {
  zile: number;
  ore: number;
  minute: number;
  secunde: number;
}

const Countdown: React.FC<CountdownProps> = ({ targetDate }) => {
  const [countdownTime, setCountdownTime] = useState<CountdownTime>(updateCountdown());
  const month = targetDate.getMonth() + 1; // Months are zero-based in JavaScript
  const day = targetDate.getDate();
  const year = targetDate.getFullYear();
  const hours = targetDate.getHours();
  const minutes = targetDate.getMinutes();

  function updateCountdown(): CountdownTime {
    let updatedCountdown: CountdownTime = {
      zile: 0,
      ore: 0,
      minute: 0,
      secunde: 0,
    };

    const countdownTimeLeft = targetDate.getTime() - new Date().getTime();
    if (countdownTimeLeft > 0) {
      updatedCountdown = {
        zile: Math.floor(countdownTimeLeft / (1000 * 60 * 60 * 24)),
        ore: Math.floor((countdownTimeLeft / (1000 * 60 * 60)) % 24),
        minute: Math.floor((countdownTimeLeft / (1000 * 60)) % 60),
        secunde: Math.floor((countdownTimeLeft / 1000) % 60),
      };
    }

    return updatedCountdown;
  }

  useEffect(() => {
    const countdownTimer = setInterval(() => {
      setCountdownTime(updateCountdown());
    }, 1000);

    return () => clearInterval(countdownTimer);
  }, [targetDate]);

  const countdownUnitFormat = (value: number) => (value < 10 ? `0${value}` : value);

  const visibleUnits = useBreakpointValue({
    base: ['zile', 'ore', 'minute'],
    md: ['zile', 'ore', 'minute', 'secunde'],
  });

  const progressBarThickness = useBreakpointValue({
    base: '4px',
    sm: '5px',
    md: '5px',
    lg: '6px',
  });

  return (
    <Box width={['80vw', '90vw', '75vw', '60vw']}>
      <svg width="0" height="0">
        <defs>
          <linearGradient id="progressGradient" x1="100%" y1="100%" x2="0%" y2="0%">
            <stop offset="0%" stopColor="rgba(32, 177, 130, 1)" />
            <stop offset="60%" stopColor="rgb(90, 200, 160)" />
            <stop offset="80%" stopColor="rgb(160, 230, 200)" />
            <stop offset="90%" stopColor="rgb(255, 255, 255)" />
          </linearGradient>
        </defs>
      </svg>
      <VStack fontFamily={theme.fonts.countdown}>
        <Text
          width={['50%', '50%', '75%', '50%']}
          textAlign="center"
          fontSize={['5vw', '6vw', '3.5vw', '2.4vw']}
          fontWeight="bold"
        >
          Au mai rămas
        </Text>
        <HStack
          width="100%"
          alignItems="center"
          justifyContent="center"
          gap="5%"
          margin={['5vw', '5vw', '3.5vw', '3.5vw']}
        >
          {Object.entries(countdownTime)
            .filter(([label]) => visibleUnits?.includes(label))
            .map(([label, value]) => {
              const maxValue = label == 'zile' ? 365 : 60;
              const progress = 100 - (value / maxValue) * 100;

              let labelText = label;

              if (label == 'secunde' && value == 1) labelText = 'secunda';
              if (label == 'zile' && value == 1) labelText = 'zi';
              if (label == 'ore' && value == 1) labelText = 'ora';
              if (label == 'minute' && value == 1) labelText = 'minut';

              return (
                <VStack
                  key={label}
                  position="relative"
                  width={['30%', '25%', '21%', '21%']}
                  aspectRatio="1/1"
                  alignContent="center"
                  justifyContent="center"
                  spacing="0"
                  borderRadius="50%"
                  borderColor="rgba(255, 255, 255, 0.1)"
                >
                  <Text
                    lineHeight="1.1"
                    fontWeight="bold"
                    fontSize={['5vw', '5vw', '3.5vw', '3vw']}
                  >
                    {countdownUnitFormat(value)}
                  </Text>
                  <Text lineHeight="1.1" fontSize={['3vw', '3vw', '2vw', '1.5vw']}>
                    {labelText}
                  </Text>
                  <CircularProgress
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    width="120%"
                    size="100%"
                    thickness={progressBarThickness}
                    trackColor="rgba(255, 255, 255, 0.1)"
                    color={'url(#progressGradient)'}
                    capIsRound
                    value={progress}
                  ></CircularProgress>
                </VStack>
              );
            })}
        </HStack>
        <Text
          width={['75%', '60%', '75%', '60%']}
          textAlign="center"
          fontSize={['3.2vw', '2.5vw', '2vw', '1.4vw']}
          fontWeight="bold"
          lineHeight="1.2"
        >
          {/* Format hours and minutes to display two digits even when there is a single digit */}
          Până la startul competiției, care va avea loc pe {day >= 10 ? day : `0${day}`}.
          {month >= 10 ? month : `0${month}`}.{year} la ora {hours >= 10 ? hours : `0${hours}`}:
          {minutes > 10 ? minutes : `0${minutes}`} (proba de GameJam va începe pe 4 aprilie în
          format online)
        </Text>
      </VStack>
    </Box>
  );
};

export default Countdown;
