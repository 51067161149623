import { Box, Text, Image, Link, VStack, HStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import SponsorCardMopdel from '../../../common/assets/CardModel.svg';
import Go from '../assets/buton_maimulte.svg';
import theme from 'src/common/styles/CustomTheme';

interface SponsorCardProps {
  type: string;
  event: string;
  description: string;
  logo: string;
  link: string;
}

const SponsorCard: React.FC<SponsorCardProps> = ({ type, event, description, logo, link }) => {
  const [isActive, setisActive] = useState(false);

  return (
    <Box
      w="100%"
      py="4.5%"
      px="15%"
      aspectRatio="96/103"
      backgroundImage={SponsorCardMopdel}
      backgroundSize="contain"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      onClick={() => setisActive(!isActive)}
    >
      <Text
        fontFamily={theme.fonts.sponsorCard}
        fontSize={{ base: '2.8vw', md: '2vw', lg: '1.4vw', xl: '1vw' }}
        color="#rgba(199, 236, 224, 1);"
        fontWeight="600"
        p={'5%'}
        height="20%"
      >
        Sponsor {type}
      </Text>
      {isActive ? (
        <VStack height="80%">
          <Box height="80%" overflowY="auto" mt="2%" pl="4%" pr="10%">
            <Text
              fontFamily={theme.fonts.sponsorDescription}
              textAlign="left"
              fontSize={{ base: '2.6vw', md: '2vw', lg: '1.4vw', xl: '0.75vw' }}
              color="rgba(255, 255, 255, 1)"
            >
              {description}
            </Text>
          </Box>
          <HStack width="90%" height="20%" justifyContent="space-between" mb="5%">
            <Image
              src={logo}
              alt={`${type} logo`}
              objectFit="contain"
              width="50%"
              maxHeight="100%"
              maxWidth="70%"
            />
            <Link href={link} isExternal>
              <Image src={Go} boxSize="100%"></Image>
            </Link>
          </HStack>
        </VStack>
      ) : (
        <>
          <VStack height="67%" justifyContent="center">
            <Image src={logo} alt={`${type} logo`} boxSize="90%" objectFit="contain" />
          </VStack>
        </>
      )}
    </Box>
  );
};
export default SponsorCard;
