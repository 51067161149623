import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  PopoverContent,
  Text,
  PopoverBody,
  PopoverCloseButton,
} from '@chakra-ui/react';

import { Popover } from '@chakra-ui/react';

import CreateTeamModal from '../pop-up/CreateTeamModal'; // Adjust the import path as necessary
import ProfileService from '../services/ProfileService'; // Import the ProfileService
import { useAuth } from 'react-oidc-context';
import icon from '../../../common/assets/inputCode.png';

const JoinTeamSection: React.FC = () => {
  const [teamCode, setTeamCode] = useState('');
  const [isCreateTeamModalOpen, setIsCreateTeamModalOpen] = useState(false);
  const [error, setError] = useState(''); // State for error messages
  const [open, setOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // State for success messages
  const auth = useAuth();

  const handleJoinTeam = async () => {
    const token = auth.user?.access_token ?? '';
    if (!token) {
      setOpen(true);
      setError('Failed to join team. No token found.');
      setSuccessMessage('');
      return;
    }
    if (!teamCode) {
      setOpen(true);
      setError('Please enter a team code.');
      setSuccessMessage('');
      return;
    }
    try {
      await ProfileService.joinTeam(token, teamCode);
      setSuccessMessage('Successfully joined the team!');
      setError('');
      window.location.reload();
    } catch (err) {
      setOpen(true);
      setError(err as string);
      setSuccessMessage('');
    }
  };
  const handleOpenCreateTeamModal = () => setIsCreateTeamModalOpen(true);
  const handleCloseCreateTeamModal = () => {
    setIsCreateTeamModalOpen(false);
    setError(''); // Clear any error when modal is closed
  };

  // Updated function to handle team creation
  const handleCreateTeam = async () => {
    handleCloseCreateTeamModal(); // Close modal after creation
    window.location.reload();
  };

  return (
    <>
      <FormControl mb="1rem">
        <FormLabel color="#C4C4C4" fontSize="1.3rem">
          Introdu codul echipei la care vrei să te alături
        </FormLabel>

        <Popover isOpen={open} colorScheme={error ? 'red' : 'green'}>
          <PopoverContent border={'none'} borderRadius={'5px'}>
            <PopoverBody
              p={'1rem'}
              color={'black'}
              bg={error ? 'red' : 'green'}
              borderRadius={'5px'}
            >
              <Text>
                {error && `${error}`}
                {successMessage && `${successMessage}`}
              </Text>

              <PopoverCloseButton
                onClick={() => {
                  setOpen(false);
                }}
              />
            </PopoverBody>
          </PopoverContent>
        </Popover>

        <InputGroup>
          <InputRightElement>
            <img src={icon} alt="icon" />
          </InputRightElement>
          <Input
            value={teamCode}
            onChange={e => setTeamCode(e.target.value)}
            placeholder="Cod echipa"
            bg="rgba(32, 177, 130, 0.1)"
            borderWidth="2px"
            borderColor="rgba(32, 177, 130, 1)"
            color="white"
            _hover={{ borderColor: 'gray.600' }}
            _focus={{ borderColor: 'teal.400' }}
            mb="2rem"
            paddingY="1.5rem"
          />
        </InputGroup>

        <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
          <Button
            bg="rgba(32, 177, 130, 0.7)"
            borderColor="rgba(32, 177, 130, 1)"
            borderWidth="2px"
            borderRadius="5px"
            color="white"
            _hover={{ bg: 'teal.600' }}
            onClick={handleJoinTeam}
            mb="1rem"
            w="fit-content"
            paddingY="2rem"
            fontSize="1.2rem"
          >
            Intră în echipă
          </Button>

          <Button
            bg="rgba(32, 177, 131, 0.2)"
            borderColor="rgba(32, 177, 130, 1)"
            borderWidth="2px"
            color="white"
            _hover={{ borderColor: 'white', color: 'white' }}
            onClick={handleOpenCreateTeamModal}
            w="fit-content"
            paddingY="2rem"
            fontSize="1.2rem"
          >
            Creează o echipă
          </Button>
        </Box>
      </FormControl>

      {/* Create Team Modal */}
      <CreateTeamModal
        isOpen={isCreateTeamModalOpen}
        onClose={handleCloseCreateTeamModal}
        onTeamCreated={handleCreateTeam} // Updated to not pass rankings
      />
    </>
  );
};
export default JoinTeamSection;
