import { useContext, useRef } from 'react';
import {
  Link,
  Flex,
  Box,
  Button,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useAuth } from 'react-oidc-context';
import { ProfileCompletedContext } from 'src/ProfileCompletedContext';
import theme from 'src/common/styles/CustomTheme';

interface INavList {
  name: string;
  link?: string;
}

interface ILinkStyle {
  textDecoration: string;
  fontSize: string;
}

const showRegister = process.env.REACT_APP_REGISTER === 'true';

const MyDrawer = (props: { navList: INavList[]; linkStyle: ILinkStyle }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);

  const auth = useAuth();

  const profileCompleted = useContext(ProfileCompletedContext);

  return (
    <>
      <Button ref={btnRef} onClick={onOpen} bg={'transparent'}>
        <HamburgerIcon w={8} h={8} />
      </Button>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerBody bg={theme.colors.navbar}>
            <Box h="2.5rem" />
            <Flex direction="column" gap="1rem" align="center">
              {profileCompleted || profileCompleted === null ? (
                props.navList.map((item, index) => (
                  <Link
                    key={index}
                    style={props.linkStyle}
                    _hover={{ textDecor: 'none' }}
                    href={item.link}
                    onClick={onClose}
                  >
                    {item.name}
                  </Link>
                ))
              ) : (
                <></>
              )}

              {showRegister ? (
                auth.isAuthenticated ? (
                  <Button
                    fontSize={{ base: '20px' }}
                    color={theme.colors.heading}
                    fontFamily={theme.fonts.navbarFont}
                    _hover={{ textDecor: 'none' }}
                    fontWeight={'400'}
                    onClick={() => {
                      void auth.removeUser().finally(() => {
                        window.location.href = '/';
                      });
                    }}
                  >
                    Log Out
                  </Button>
                ) : (
                  <Button
                    fontSize={{ base: '20px' }}
                    color={theme.colors.heading}
                    fontFamily={theme.fonts.navbarFont}
                    _hover={{ textDecor: 'none' }}
                    fontWeight={'400'}
                    onClick={() => {
                      void auth.signinRedirect();
                    }}
                  >
                    Logare
                  </Button>
                )
              ) : (
                <></>
              )}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MyDrawer;
