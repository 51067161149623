import {
  VStack,
  Text,
  Box,
  Collapse,
  Button,
  HStack,
  Image,
  Input,
  Textarea,
  useToast,
  Link,
  Divider,
} from '@chakra-ui/react';
import { useState } from 'react';
import theme from 'src/common/styles/CustomTheme';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ContactBG from './assets/bg-contact.svg';
import e_box from './assets/email-box.svg';
import m_box from './assets/mesaj-box.svg';

import { useAuth } from 'react-oidc-context';
import ContactService from './services/ContactService';
import FetchId from 'src/common/services/IdService';
import Carousel from './Components/Carousel';

const Contact = () => {
  const auth = useAuth();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const toast = useToast();
  const handleSend = async () => {
    const token = auth.user?.access_token ?? '';

    try {
      toast({
        title: 'Mesajul se trimite...',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });

      await ContactService.sendMessage(token, email, message);

      toast.closeAll();

      toast({
        title: 'Mesaj trimis cu succes!',
        description: 'Vă mulțumim pentru interesul acordat!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setEmail('');
      setMessage('');
    } catch (error) {
      toast.closeAll();

      console.log('Error sending the mail for contact: ');
      console.log(error);
      toast({
        title: 'A fost o eroare!',
        description: 'Încercați din nou.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack spacing={['3vh', '3vh']} mt="5vh" id={FetchId.Contact}>
      <Text
        fontFamily={theme.fonts.sectionTitle}
        fontWeight={'400'}
        fontSize={['8vw', '4vw']}
        color="#DEDEDE"
        alignSelf={['left', 'flex-start']}
        padding={['0 20%']}
      >
        Contact
      </Text>
      <Box
        backgroundImage={ContactBG}
        backgroundPosition="center"
        backgroundSize="100% 100%"
        backgroundRepeat="no-repeat"
        width={['70vw', '50vw']}
        height={['55vw', '40vw']}
        display="flex"
        justifyContent="center"
        overflow={'hidden'}
        marginBottom="5vh"
      >
        <VStack width={['70%']} height={['90%', '90%']} alignSelf={['center', 'center']} gap="0">
          <Text
            paddingLeft={['5%', '0']}
            fontFamily={theme.fonts.contact}
            fontWeight="600"
            fontSize={['3vw', '2vw']}
            color="#8CD6BE"
            textAlign="left"
            width="100%"
          >
            E-mail
          </Text>
          <Input
            backgroundImage={e_box}
            backgroundPosition="center"
            bgSize={'100% 100%'}
            px={['8%', '8%']}
            py={['3%', '3%']}
            width="100%"
            height={['22%', '19%']}
            backgroundRepeat="no-repeat"
            fontSize={['2.5vw', '1.6vw']}
            fontFamily={theme.fonts.contact}
            textColor={'#8CD6BE'}
            border="0"
            sx={{
              '&:focus': {
                border: '0',
                boxShadow: 'none',
              },
            }}
            value={email}
            onChange={e => setEmail(e.target.value)}
          ></Input>
          <Text
            pt={['5%', '5%']}
            paddingLeft={['5%', '0']}
            fontFamily={theme.fonts.contact}
            fontWeight="600"
            fontSize={['3vw', '2vw']}
            color="#8CD6BE"
            textAlign="left"
            width="100%"
          >
            Mesaj
          </Text>
          <Box
            width="100%"
            height={['40%', '36%']}
            justifyContent={'center'}
            overflow={'hidden'}
            backgroundImage={m_box}
            backgroundRepeat="no-repeat"
            px={['8%', '8%']}
            py={['3%', '3%']}
            bgSize={'100% 100%'}
            scrollPaddingY={['20%', '0']}
          >
            <Box width="100%" height="100%" position="relative" overflow="hidden">
              <Textarea
                padding="0"
                bgImage={'none'}
                maxHeight={'80%'}
                height={['1px', '300px']}
                width={'100%'}
                fontSize={['2.5vw', '1.6vw']}
                fontFamily={theme.fonts.contact}
                textColor={'#8CD6BE'}
                border="0"
                resize="none"
                sx={{
                  '&:focus': {
                    border: '0',
                    boxShadow: 'none',
                  },
                }}
                value={message}
                onChange={e => setMessage(e.target.value)}
              ></Textarea>
            </Box>
          </Box>
          <Button
            backgroundColor={'rgba(32, 177, 130, 0.3)'}
            mt={['2%', '8%']}
            alignSelf={['center', 'center']}
            height={['15%', '15%']}
            width={['35%', '30%']}
            fontSize={['2vw', '1.5vw']}
            fontFamily={theme.fonts.contact}
            color="#C7ECE0"
            border="solid #20B182"
            borderWidth={['2px', '4px']}
            borderRadius={['2vw', '0.5vw']}
            onClick={handleSend}
          >
            Trimite
          </Button>
        </VStack>
      </Box>
      <Text
        fontFamily={theme.fonts.sectionTitle}
        fontSize={['8vw', '4vw']}
        color="#DEDEDE"
        mt="5vh"
      >
        Întâlnește echipa
      </Text>
      <Carousel />
    </VStack>
  );
};

export default Contact;
