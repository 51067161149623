import { Container, SimpleGrid, Text } from '@chakra-ui/react';
import Slider from 'react-slick';
import FeedbackCard from './FeedbackCard';
import { ArrowButton } from 'src/common/components/Button/ArrowIconButton';
import Next from './assets/Next.svg';
import Prev from './assets/Prev.svg';
import theme from 'src/common/styles/CustomTheme';

const FeedbackCarousel = () => {
  const feedback = [
    {
      author: 'Popa Razvan',
      description:
        'A fost foarte tare, sunt 24 de ore in care chiar faci ceva util, inveti chestii noi, razi. Nu ma asteptam sa castigam pentru ca erau foarte multe echipe bune, dar a fost o surpriza foarte mare si ne-am bucurat super mult.',
    },
    {
      author: 'Napruiu Andrei',
      description:
        'E un sentiment foarte placut. Mai ales ca a fost prima data cand am participat si sa castigi locul I mi-am dat seama ca nu e imposibil. Clar am avut o concurenta acerba, dar mi-am depasit destule limite (inclusiv pe cea a dormitului) pentru a ajunge la rezultatul dorit. Niciodată nu e prea târziu să participi, important e sa vii in competitia asta cu inima deschisa si impacat cu oricare ar fi rezultatul, pentru ca, pana la urma, mai câștigat te simți lucrand la o idee si avand un produs la final.',
    },
    {
      author: 'Napruiu Andrei',
      description:
        '      Aș repeta experiența oricând! Nu ar trebui să existe prea devreme sau prea tarziu, important e sa vii la HackITall cu inima deschisă și impacat cu oricare ar fi rezultatul. Câștigul îl ai oricum lucrând la o idee și reușind să prezinți un produs final.',
    },
    {
      author: 'Osnaga Octavian-Alexandru',
      description: 'O experiență interesantă plină de provocări palpitante.',
    },
    {
      author: 'Gavrilă Alexandru',
      description:
        'A fost o experiență inedită, cu multe râsete, multe momente în care nu știam cum se va termina, dar la final totul a prins contur. Evenimentul a fost organizat superb, având orice lucru de care aveam nevoie, de la zone de dormit și relaxare până la zone de mâncare și de brainstorming. Reiterez, o experiență inedită!',
    },
    {
      author: 'Carp Alexandru',
      description:
        'O experiență completă de hackathon, cu un challenge care ne-a ținut în priză pentru 24h, mancare bună, o atmosfera frumoasă și tot ce e nevoie pentru a fi în cea mai bună formă.',
    },
    {
      author: 'Gătej Ștefan-Alexandru ',
      description:
        'Munca intensă. Ore nedormite. Câțiva nervi. In the end, it was worth it, și as participa de o mie de ori din nou.',
    },
    {
      author: 'Radu Andrei-Laurentiu',
      description:
        'A fost o experiență fantastică. De la "nu merge nimic" la "e gata" e o diferență foarte mică.',
    },
    {
      author: 'Niță Eduard-Eugen',
      description:
        'Fără a exagera, pot spune că HackITall te transformă ca persoană. Pe lângă experiența de competiție, în care cunoști unii dintre cei mai experimentați studenți programatori, ai parte de un mod de a-ți accelera absolut toate abilitățile: coding, product skills, pitching, ambiție, reziliență - you hack your growth. Capeți o gândire orientată foarte specific către găsirea rapidă a unei soluții pe care o construiești de la 0 și nu cred că există un sentiment mai frumos decât împlinirea pe care o ai când vezi aplicația funcțională, după ce ai lucrat continuu, 24 de ore. Chiar dacă pare clișeic, orice participant este câștigător, indiferent de ce se întâmplă în cadrul hackathonului, tocmai datorită modului în care înveți lucruri extrem de rapid, de multe ori de nevoie. Nu există loc mai bun în care să îți expui creativitatea și să experimentezi orice ține de IT, indiferent că vorbim de software sau hardware. La final, premiul cel mai mare al HackITall sunt oamenii cu care ai lucrat cot la cot, cu care ai pierdut ore de somn și care la final de competiție îți devin prieteni.',
    },
  ];

  return (
    <SimpleGrid
      columns={{ base: 1, md: 2 }}
      maxW={{ base: '100%', md: '90%', lg: '80%', xl: '70%' }}
      margin="auto"
      marginBottom={{ base: '20vw', md: '10vw' }}
    >
      <Container
        minW="fit-content"
        textAlign="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Text
          minW="fit-content"
          fontFamily={theme.fonts.sectionTitle}
          fontWeight="400"
          overflow="hidden"
          margin-left="auto"
          margin-top="auto"
          fontSize={['6vw', '6vw', '4vw', '3vw']}
          color="#FFFFFF"
          textAlign="left"
        >
          Feedback
        </Text>
        <Text
          minW="fit-content"
          overflow="hidden"
          fontFamily={theme.fonts.sectionTitle}
          fontWeight="400"
          fontSize={['6vw', '6vw', '4vw', '3vw']}
          textAlign="left"
          color="#20B182"
        >
          ediții{'\u00A0'}anterioare
        </Text>
      </Container>

      <Container justifyContent="center">
        <Slider
          swipeToSlide={true}
          slidesToShow={1}
          centerMode={true}
          centerPadding="0"
          autoplay={true}
          autoplaySpeed={5000}
          nextArrow={<ArrowButton type="right" src={Next} />}
          prevArrow={<ArrowButton type="left" src={Prev} />}
        >
          {feedback.map((feedback, index) => (
            <FeedbackCard author={feedback.author} description={feedback.description} key={index} />
          ))}
        </Slider>
      </Container>
    </SimpleGrid>
  );
};

export default FeedbackCarousel;
