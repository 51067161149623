import * as ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { ColorModeScript } from '@chakra-ui/react';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { App } from './App';

const oidcConfig: AuthProviderProps = {
  authority: process.env.REACT_APP_SSO_AUTHORITY,
  client_id: process.env.REACT_APP_SSO_CLIENTID,
  redirect_uri: process.env.REACT_APP_SSO_REDIRECT_URI,
  post_logout_redirect_uri: process.env.REACT_APP_SSO_REDIRECT_URI,
  scope: process.env.REACT_APP_SSO_SCOPES,

  onSigninCallback: () => {
    // this is how you remove the code and state from the url. DO IT!
    window.history.replaceState({}, document.title, window.location.pathname);

    return Promise.resolve();
  },
};

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <AuthProvider {...oidcConfig}>
    <ColorModeScript />
    <App />
  </AuthProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
