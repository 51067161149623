import { Grid, VStack, Text, Image, Link } from '@chakra-ui/react';
import EventCard from './components/EventCard';
import ChenarHack from './assets/chenar_hack.png';
import ChenarGamejam from './assets/chenar_gamejam.png';
import ChenarSide from './assets/chenar_side.png';
import Croco from './assets/croco.png';
import FetchId from 'src/common/services/IdService';
import theme from 'src/common/styles/CustomTheme';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const Events = () => {
  return (
    <VStack mt="5%" mb="5%" id={FetchId.Events}>
      <Text
        color="DEDEDE"
        fontFamily={theme.fonts.sectionTitle}
        fontSize={['7vw', '6vw', '4vw']}
        position={['static', 'relative']}
        right="30vw"
      >
        Probe
      </Text>
      <Grid
        templateColumns={['repeat(1,1fr)', 'repeat(1,1fr)', 'repeat(3,1fr)']}
        gap={['1rem', '2rem', '3rem']}
        justifyItems="center"
        position="relative"
        py="5vh"
      >
        <EventCard
          position="relative"
          bottom={{ base: '0rem', md: '2.5rem' }}
          title="Hackaton"
          description="
            Aceasta proba presupune dezvoltarea unor proiecte
            software complete în doar 24 de ore,
            tematica pe baza căreia acestea vor fi
            elaborate fiind furnizată de sponsorii principali.
          "
          background={ChenarHack}
        />
        <EventCard
          position="relative"
          top={{ base: '0rem', md: '5rem' }}
          title="Side Challenge"
          description={
            <Text>
              O probă ce pune la încercare studenții, care, timp de 10 ore, își vor testa
              ingeniozitatea și capacitatea de a gândi strategic.
            </Text>
          }
          background={ChenarSide}
        />
        <EventCard
          position="relative"
          bottom={{ base: '0rem', md: '2.5rem' }}
          title="Game Jam"
          description="
            Proba de Game Jam încurajează concurenții să
            creeze un joc captivant, oferindu-le acestora posibilitatea de a
            interacționa îndeaproape cu industria de game
            development.
          "
          background={ChenarGamejam}
        />
        <Image
          position="absolute"
          bottom={{ base: '0%', md: '2%', lg: '-12.5%' }}
          right={{ base: '0%', md: '-15%', lg: '31%' }}
          src={Croco}
          w={{ base: '0vw', md: '7vw' }}
        />
      </Grid>
    </VStack>
  );
};

export default Events;
