import { VStack, Text, useBreakpointValue, Flex, Box } from '@chakra-ui/react';
import { Element } from 'react-scroll';
import theme from 'src/common/styles/CustomTheme';

const About = () => {
  const videoUrl = process.env.REACT_APP_VIDEO_URL;
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box width="100vw" mt="10vh">
      <Flex
        direction={isMobile ? 'column' : 'row'}
        justifyContent="space-evenly"
        alignItems="center"
        height="100%"
      >
        <Element name="about" />

        <VStack
          spacing={['5vh', '5vh']}
          mb="10vh"
          width={['80vw', '45vw']}
          alignItems={['center', 'flex-start']}
        >
          <VStack spacing="0vh" alignItems={['center', 'flex-start']}>
            <Text fontFamily={theme.fonts.sectionTitle} fontSize={['8vw', '6vw', '5vw', '3vw']}>
              Ce este
            </Text>
            <Text
              fontFamily={theme.fonts.sectionTitle}
              fontSize={['8vw', '6vw', '5vw', '3vw']}
              color="#20B182"
            >
              HackITall
            </Text>
          </VStack>
          <Text
            fontFamily={theme.fonts.description}
            fontWeight="700"
            fontSize={['3.4vw', '2.5vw', '2vw', '1.5vw']}
            textAlign={['center', 'left']}
            color="#90D8C1"
          >
            <strong>
              HackITall este un hackathon de amploare, organizat de studenți pentru studenți, care
              își propune să ofere o experiență unică și să încurajeze inovația în rândul tinerelor
              talente.
            </strong>
          </Text>
          <Text
            fontFamily={theme.fonts.description}
            fontWeight="700"
            fontSize={['3vw', '2.25vw', '2vw', '1vw']}
            textAlign={['center', 'left']}
            color="#C4C4C4"
          >
            HackITall continuă să evolueze cu fiecare ediție, depășind constant așteptările și
            devenind tot mai popular în rândul studenților. Competiția de tip hackathon, organizată
            de LSAC (Liga Studenților din Facultatea de Automatică și Calculatoare), își propune să
            testeze abilitățile studenților pasionați de tehnologie și industria IT&C, totodată
            oferindu-le acestora oportunități de dezvoltare în carieră.
            <br />
            <br />
            HackITall dă șansa tinerilor creativi să interacționeze cu reprezentanți ai firmelor
            care sunt alături de eveniment, constituind pentru mulți dintre aceștia o rampă de
            lansare în industria IT&C, la baza căreia stă întotdeauna creativitatea. În cadrul
            competiției, atmosfera destinsă și adrenalina se completează perfect, reușind
            întotdeauna să pună bazele unor proiecte extraordinare.
          </Text>
        </VStack>
        <VStack justifyContent="center" width={['80vw', '32vw']}>
          <iframe
            src={videoUrl}
            allow="autoplay"
            allowFullScreen
            style={{
              width: '100%',
              aspectRatio: '16/9',
            }}
          ></iframe>
        </VStack>
      </Flex>
    </Box>
  );
};

export default About;
