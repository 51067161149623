import { Box, VStack, Text, Image } from '@chakra-ui/react';
import PartnerCardMopdel from '../../../common/assets/CardModel.svg';
import theme from 'src/common/styles/CustomTheme';

interface PartnerCardProps {
  title: string;
  logo: string;
}

const PartnerCard: React.FC<PartnerCardProps> = ({ title, logo }) => {
  return (
    <Box
      w="100%"
      py="4.5%"
      px="15%"
      aspectRatio="96/103"
      backgroundImage={PartnerCardMopdel}
      backgroundSize="contain"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
    >
      <VStack align="center" justify="center" height="100%">
        <Text
          fontFamily={theme.fonts.sponsorCard}
          fontSize={['16px', '20px']}
          color="#rgba(199, 236, 224, 1)"
          fontWeight="600"
          p={'5%'}
          height="20%"
          textAlign="center"
        >
          {title}
        </Text>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="80%"
          p="8%"
          pb="28%"
        >
          <Image
            src={logo}
            alt={`${title} logo`}
            objectFit="contain"
            maxHeight="90%"
            maxWidth="100%"
          />
        </Box>
      </VStack>
    </Box>
  );
};

export default PartnerCard;
