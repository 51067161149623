import {
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Alert,
  AlertIcon,
  FormControl,
  FormLabel,
  Input,
  Select,
  Spinner,
  ModalFooter,
  Text,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import ProfileService from '../services/ProfileService';
import { HashLink } from 'react-router-hash-link';

interface ModalProps {
  type: 'create' | 'manage' | 'view';
  rankings: number[];
  teamName?: string;
  loading: boolean;
  teamMembers?: { id: string; name: string }[];
  captainId?: string | null;
  openKickModal?: (member: { id: string; name: string }) => void;
  setTeamName: (name: string) => void;
  setRankings: (rankings: number[]) => void;
  handleSave: () => void;
  error: string;
  setError: (error: string) => void;
}

const ModalTeam = ({
  type,
  rankings,
  teamName,
  loading,
  teamMembers,
  captainId,
  openKickModal,
  setTeamName,
  setRankings,
  handleSave,
  error,
  setError,
}: ModalProps) => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [events, setEvents] = useState<{ id: string; name: string }[]>([]);
  const [areRankingsValid, setAreRankingsValid] = useState(false);

  const fetchData = async () => {
    const errorMsg = 'Failed to fetch events.';
    if (error === errorMsg) setError('');

    const token = auth.user?.access_token ?? '';

    try {
      const fetchedEvents = await ProfileService.fetchEvents(token);
      setEvents(fetchedEvents);
    } catch {
      setError(errorMsg);
    }
  };

  useEffect(() => {
    const token = auth.user?.access_token ?? undefined;

    if (token) {
      fetchData();
    }
  }, [auth.user?.access_token]);

  useEffect(() => {
    validateForm();
  }, [rankings]);

  const validateForm = () => {
    const hasNonDefault = rankings.some(ranking => ranking !== 0 && !isNaN(ranking));
    setAreRankingsValid(hasNonDefault);
  };

  const handleRankingChange = (index: number, value: number) => {
    const newRankings = [...rankings];
    newRankings[index] = value;
    setRankings(newRankings);
  };

  const handleResetRankings = () => {
    setRankings(Array(events.length).fill(0)); // Reset rankings to 0
  };

  // Used to disable already selected rankings
  const usedRankings = new Set(rankings.filter(r => r !== 0));

  return (
    <ModalContent
      bgGradient="linear-gradient(to-t,rgba(32, 177, 130, 0.7) ,rgba(32, 177, 130, 0.8) ,rgba(0, 22, 35, 0.9) 0% ,rgba(0, 22, 35, 1) 99% )"
      color="white"
      borderRadius="20px"
    >
      <ModalHeader fontWeight="bold" fontSize="2xl" textAlign="center">
        Creează Echipa
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {error && (
          <Alert status="error" mb="1rem" backgroundColor="red">
            <AlertIcon color="black" />
            {error}
          </Alert>
        )}
        <FormControl mb="1rem">
          <FormLabel>{type === 'create' ? 'Nume Echipa' : 'Numele echipei'}</FormLabel>
          <Input
            disabled={type === 'view' ? true : false}
            value={teamName}
            onChange={e => setTeamName(e.target.value)}
            placeholder="Introdu nume"
            bg="rgba(32, 177, 130, 0.1)"
            color="white"
            borderWidth="2px"
            borderColor="rgba(32, 177, 130, 1)"
          />
        </FormControl>
        {(type == 'manage' || type === 'create') && (
          <Text mb="1rem">Alege ordinea preferințelor la probe: (minim 1)</Text>
        )}
        <Button
          bg="rgba(32, 177, 130, 0.7)"
          mb="1rem"
          borderWidth="2px"
          borderColor="rgba(32, 177, 130, 1)"
          onClick={() => navigate('/#Events')}
        >
          <HashLink smooth to="/#Events" color="#DEDEDE">
            Informații despre probe
          </HashLink>
        </Button>

        <Text fontSize="sm" mt="0.5rem" color="#ABABAB" mb="0.5rem">
          1 este proba la care vreți cel mai mult sa participați. &quot;Neselectată&quot; înseamnă
          că nu doriți sa participați la acea probă.
        </Text>
        {events.map((event, index) => (
          <FormControl key={event.id} mb="1rem">
            <FormLabel>{event.name}</FormLabel> {/* Use event name */}
            <Select
              disabled={type === 'view' ? true : false}
              placeholder="Neselectată"
              value={rankings[index] || ''}
              onChange={e => handleRankingChange(index, parseInt(e.target.value))}
              bg="rgba(32, 177, 130, 1)"
              color="white"
              css={{
                option: {
                  backgroundColor: 'rgba(32, 177, 130, 0.1)',
                  border: '2px solid rgba(32, 177, 130, 1)',
                  color: 'white',
                },
              }}
            >
              {Array.from({ length: events.length }, (_, i) => (
                <option
                  key={i}
                  value={i + 1}
                  disabled={
                    (usedRankings.has(i + 1) && rankings[index] !== i + 1) || type === 'view'
                  }
                >
                  {i + 1}
                </option>
              ))}
            </Select>
          </FormControl>
        ))}

        {captainId === auth.user?.profile.sub && (
          <Button
            bg="rgba(32, 177, 130, 0.7)"
            borderWidth="2px"
            borderColor="rgba(32, 177, 130, 1)"
            mb="1rem"
            onClick={handleResetRankings}
          >
            Resetează preferințele
          </Button>
        )}

        {captainId === auth.user?.profile.sub && type === 'manage' ? (
          <>
            {' '}
            <Text mb="1rem">Membrii echipei:</Text>
            <UnorderedList color="white" listStyleType="none">
              {teamMembers?.map((member, index) => (
                <ListItem
                  key={index}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb="0.5rem"
                >
                  <Text>{member.name}</Text>
                  {captainId === auth.user?.profile.sub && (
                    <Button colorScheme="red" onClick={() => openKickModal!(member)}>
                      Kick
                    </Button>
                  )}
                </ListItem>
              ))}
            </UnorderedList>
          </>
        ) : (
          <></>
        )}

        {loading && <Spinner size="lg" />}
      </ModalBody>

      {(captainId === auth.user?.profile.sub || type == 'create') && (
        <ModalFooter justifyContent="center">
          <Button
            bg="rgba(32, 177, 130, 0.7)"
            borderWidth="2px"
            borderColor="rgba(32, 177, 130, 1)"
            disabled={!areRankingsValid}
            onClick={handleSave}
            isLoading={loading}
          >
            {type === 'create' ? 'Creează Echipa' : 'Salvează schimbările'}
          </Button>
        </ModalFooter>
      )}
    </ModalContent>
  );
};

export default ModalTeam;
