import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Text,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { FaClipboard } from 'react-icons/fa';
import ManageTeamModal from '../pop-up/ManageTeamModal';
import ProfileService from '../services/ProfileService';
import { useAuth } from 'react-oidc-context';
import MessageModal from '../pop-up/messageModal';

const TeamSection: React.FC = () => {
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
  const [isManageModalOpen, setIsManageModalOpen] = useState(false);
  const [teamCode, setTeamCode] = useState<string | null>(null); // Stores the team ID
  const [teamMembers, setTeamMembers] = useState<{ id: string; name: string }[]>([]); // Stores team members with ID
  const [captainId, setCaptainId] = useState<string | null>(null); // Stores captain ID
  const [isCaptain, setIsCaptain] = useState<boolean>(false); // Tracks if the user is the captain
  const auth = useAuth();

  useEffect(() => {
    const fetchTeamData = async () => {
      const token = auth.user?.access_token ?? '';
      try {
        // Fetch team information from the backend
        const teamData = await ProfileService.fetchTeamData(token);
        setTeamCode(teamData.id); // Extract the team ID as the code
        setTeamMembers(
          teamData.members.map((member: any) => ({ id: member.id, name: member.name })),
        ); // Extract members list
        setCaptainId(teamData.capitanId); // Set the captain ID

        const userId = auth.user?.profile.sub; // Assuming user's ID is stored in `sub`
        if (userId === teamData.capitanId) {
          setIsCaptain(true); // User is the captain
        } else {
          setIsCaptain(false); // User is not the captain
        }
      } catch (error) {
        console.error('Failed to fetch team data:', error);
      }
    };

    if (auth.user?.access_token) {
      fetchTeamData();
    }
  }, [auth.user?.access_token]);

  const handleOpenLeaveModal = () => setIsLeaveModalOpen(true);
  const handleCloseLeaveModal = () => setIsLeaveModalOpen(false);
  const handleOpenManageModal = () => setIsManageModalOpen(true);
  const handleCloseManageModal = () => setIsManageModalOpen(false);

  const handleConfirmLeave = async () => {
    const token = auth.user?.access_token ?? '';
    await ProfileService.leaveTeam(token);
    handleCloseLeaveModal();
    window.location.reload();
  };

  const handleKickMember = (memberId: string) => {
    // Logic to kick a member
    alert(`Ai dat kick membrului cu id: ${memberId}`);
  };

  const copyToClipboard = async () => {
    if (teamCode) {
      try {
        await navigator.clipboard.writeText(teamCode);
        alert('Codul echipei a fost copiat în clipboard!');
      } catch (err) {
        console.error('Eroare la copiere: ', err);
      }
    }
  };

  return (
    <>
      <FormControl mb="1rem" mt="1rem">
        <FormLabel color="#C4C4C4">Codul echipei</FormLabel>
        <Box display="flex">
          <Text
            bg="transparent"
            borderColor="gray.400"
            borderWidth="1px"
            color="white"
            p="0.5rem"
            borderRadius="md"
            _hover={{ borderColor: 'gray.600' }}
            _focus={{ borderColor: 'teal.400' }}
            width="100%"
          >
            {teamCode || 'Loading...'}
          </Text>
          <IconButton
            ml="0.5rem"
            icon={<FaClipboard />}
            aria-label="Copy Code"
            colorScheme="teal"
            variant="outline"
            borderColor="gray.400"
            onClick={copyToClipboard}
            isDisabled={!teamCode} // Disable if teamCode is not available yet
          />
        </Box>

        <UnorderedList mt="1rem" color="white" listStyleType="none">
          {teamMembers.length > 0 ? (
            teamMembers.map(member => (
              <ListItem key={member.id} display="flex" alignItems="center">
                <Text color={member.id === captainId ? 'gold' : 'white'}>{member.name}</Text>
                {member.id === captainId && (
                  <Text color="gold" marginLeft="10px">
                    (Capitan)
                  </Text>
                )}
              </ListItem>
            ))
          ) : (
            <Text color="gray.400">Nu există membri</Text>
          )}
        </UnorderedList>

        {/* show "Manage Team" button if the user is the team captain else show view team info*/}
        {
          <Box display="flex" flexDirection="column" mt="1rem" alignItems="center">
            <Button
              bg="rgba(32, 177, 130, 0.2)"
              borderColor="rgba(32, 177, 130, 1)"
              borderWidth="2px"
              color="white"
              _hover={{ borderColor: 'white', color: 'white' }}
              w="fit-content"
              mb="1.5rem"
              py={'1.5rem'}
              fontSize={'1.2rem'}
              onClick={handleOpenManageModal}
            >
              {isCaptain ? 'Gestionează Echipa' : 'Detalii Echipă'}
            </Button>
          </Box>
        }

        <Box display="flex" flexDirection="column" mt="1rem" alignItems="center">
          <Button
            bg="red.500"
            borderColor="red.500"
            borderWidth="1px"
            color="white"
            _hover={{ bg: 'red.600' }}
            w="fit-content"
            py={'1.5rem'}
            fontSize={'1.2rem'}
            onClick={handleOpenLeaveModal}
          >
            Părăsește Echipa
          </Button>
        </Box>
      </FormControl>

      <MessageModal
        isOpen={isLeaveModalOpen}
        onClose={handleCloseLeaveModal}
        onConfirm={handleConfirmLeave}
        type={'leave'}
      />

      <ManageTeamModal // Pass the actual team ID
        isOpen={isManageModalOpen}
        type={isCaptain ? 'manage' : 'view'}
        onClose={handleCloseManageModal}
      />
    </>
  );
};

export default TeamSection;
