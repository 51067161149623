import React from 'react';
import teamIcon from '../assets/TeamMember.svg';
import { Image, Text } from '@chakra-ui/react';
import ReactCardFlip from 'react-card-flip';
import theme from 'src/common/styles/CustomTheme';

interface teamMember {
  image: string;
  name: string;
  title: string;
}

const CarouselCard: React.FC<teamMember> = ({ image, name, title }) => {
  const [isFlipped, setIsFlipped] = React.useState(false);

  function flipCard() {
    setIsFlipped(!isFlipped);
  }

  return (
    <ReactCardFlip
      flipDirection="horizontal"
      isFlipped={isFlipped}
      containerStyle={{ padding: '5%' }}
    >
      <>
        <Image onClick={flipCard} src={teamIcon} width="30vw" />
        <Image onClick={flipCard} position="absolute" src={image} alt={name} top="0" padding="6%" />
      </>

      <>
        <Image onClick={flipCard} src={teamIcon} width="30vw" />
        <Text
          onClick={flipCard}
          position="absolute"
          top="0"
          padding="7%"
          fontFamily={theme.fonts.teamMembersCard}
          color="#20B182"
          width="85%"
          fontSize={{ base: '2.2vw', lg: '1.4vw', xl: '1vw' }}
        >
          {title}
        </Text>
      </>
    </ReactCardFlip>
  );
};

export default CarouselCard;
