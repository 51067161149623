import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import UpdateProfileModal from './pop-up/updateProfile';
import TeamSection from './components/TeamSection'; // Import TeamSection
import JoinTeamSection from './components/JoinTeamSection';
import ProfileService from './services/ProfileService';
import { useAuth } from 'react-oidc-context';

import border from '../../common/assets/chenarProfil.png';
import matrix from '../../common/assets/bgMatrixProfile.svg';
import MessageModal from './pop-up/messageModal';

const MyProfile: React.FC = () => {
  const placeholderUserName = 'Nume';
  const auth = useAuth();

  const [name, setName] = useState<string>(placeholderUserName);
  const [inTeam, setInTeam] = useState<boolean>(false); // Use state for inTeam

  const { isOpen: isUpdateOpen, onOpen: onUpdateOpen, onClose: onUpdateClose } = useDisclosure();
  const { isOpen: isLeaveOpen, onOpen: onLeaveOpen, onClose: onLeaveClose } = useDisclosure(); // Manage Leave Account modal
  const [hasCv, setHasCv] = useState(false);

  useEffect(() => {
    if (auth.isLoading) return;
    if (!auth.isAuthenticated) {
      window.location.href = '/';
    }

    window.scrollTo(0, 0);
    const token = auth.user?.access_token ?? '';

    const fetchData = async () => {
      try {
        const userInfo = await ProfileService.getUserInfo(token);
        setName(userInfo.givenName + ' ' + userInfo.familyName);

        if (userInfo.teamId) {
          setInTeam(true); // Set inTeam if user is in a team
        } else {
          setInTeam(false);
        }

        setHasCv(!!userInfo.CV);
      } catch (error) {
        console.error('Failed to fetch user data: ', error);
      }
    };

    void fetchData();
  }, [auth]);

  const handleConfirmLeave = async () => {
    const token = auth.user?.access_token ?? '';
    await ProfileService.deleteAccount(token)
      .then(auth.removeUser)
      .finally(() => (window.location.href = '/'));
  };

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      minH="100vh"
      bgImage={{ base: 'none', md: matrix }}
      bgSize="100%"
      bgRepeat="no-repeat"
      bgPosition="center"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      color="white"
      p="20px"
    >
      {/* Should I need to chnage to a Flex component? */}
      <Box
        bgImage={{ base: '', md: border, lg: border }}
        bgSize="100% 100%"
        bgPosition="center"
        bgRepeat="no-repeat"
        //add a border for mobile because that image isn't good
        border={{ base: '2px ', md: 'none' }}
        borderColor={'rgb(32, 177, 130)'}
        borderRadius={'5px'}
        py={'3rem'}
        px={{ base: '2rem', md: '3rem', lg: '3rem', xl: '3rem' }}
        // w={{ base: '80vw', md: '95vw', lg: '85vw', xl: '95vh' }}
        w="fit-content"
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        mb={{ base: '-1rem', sm: '0rem' }}
      >
        <Grid templateColumns={['1fr', '1fr 1fr']} gap={{ sm: 5, md: 20 }} mt="1rem">
          <GridItem>
            <FormControl mb="1rem">
              <FormLabel color="#C4C4C4" fontSize="1.5rem">
                Nume utilizator
              </FormLabel>
              <Box display="flex">
                <Text
                  bg="rgba(32, 177, 130, 0.1)"
                  borderColor="rgba(32, 177, 130, 1)"
                  borderWidth="2px"
                  color="white"
                  padding="0.7rem"
                  borderRadius="5px"
                  _hover={{ borderColor: 'gray.600' }}
                  _focus={{ borderColor: 'teal.400' }}
                  width="100%"
                  fontSize="1.2rem"
                >
                  {name}
                </Text>
              </Box>
              <Text fontSize="md" mt="0.5rem" color="#ABABAB">
                Preluat din SSO. Nu se poate modifica.
              </Text>
            </FormControl>

            <Box mt="1rem" display="flex" flexDirection="column" alignItems="center">
              <Text
                fontSize="md"
                color="#ABABAB"
                textAlign="center"
                mt={{ sm: '1rem', md: '2rem' }}
                mb="0.5rem"
                width="100%"
              >
                Dacă au apărut modificări poți actualiza datele personale.
              </Text>
              <Button
                bg="rgba(32, 177, 130, 0.2)"
                borderColor="rgba(32, 177, 130, 1)"
                borderWidth="2px"
                color="white"
                _hover={{ borderColor: 'white', color: 'white' }}
                w="fit-content"
                py="1.5rem"
                fontSize="1.2rem"
                mb={{ sm: '1rem', md: '0rem' }}
                onClick={onUpdateOpen}
              >
                Actualizează date
              </Button>
            </Box>
          </GridItem>

          <GridItem>
            {inTeam ? (
              <TeamSection /> // No need to pass teamCode or onCopyCode
            ) : (
              <JoinTeamSection />
            )}
          </GridItem>
        </Grid>

        <Button
          alignSelf="center"
          bg="rgba(255,0,0,0.2)"
          color="white"
          _hover={{ bg: 'red.600' }}
          mt={{ md: '0rem' }}
          w="fit-content"
          borderColor="red.500"
          borderWidth="2px"
          borderRadius="5px"
          paddingY={{ lower_sm: '1.5rem', sm: '2rem' }}
          fontSize="1.2rem"
          onClick={onLeaveOpen}
        >
          Șterge contul
        </Button>
      </Box>
      {hasCv ? (
        <></>
      ) : (
        <Text
          borderColor="red.500"
          borderWidth="2px"
          borderRadius="5px"
          bg="rgba(255,0,0,0.2)"
          fontSize="1.2rem"
          padding="1.2rem"
          mt="2rem"
        >
          Nu ați încărcat niciun CV!
        </Text>
      )}

      <MessageModal
        type="delete"
        isOpen={isLeaveOpen}
        onClose={onLeaveClose}
        onConfirm={handleConfirmLeave}
      />
      <UpdateProfileModal
        isOpen={isUpdateOpen}
        onClose={onUpdateClose}
        onConfirmClose={() => (window.location.href = '/profile')}
      />
    </Box>
  );
};

export default MyProfile;
