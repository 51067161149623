import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Select,
  Input,
  Textarea,
  Box,
  Spinner,
  Alert,
  AlertIcon,
  HStack,
} from '@chakra-ui/react';
import { useAuth } from 'react-oidc-context';
import ProfileService from '../services/ProfileService';

interface UpdateProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirmClose: () => void;
}

const UpdateProfileModal: React.FC<UpdateProfileModalProps> = ({
  isOpen,
  onClose,
  onConfirmClose,
}) => {
  const shirtSizes = ['S', 'M', 'L', 'XL'];
  const pronouns = ['He/Him', 'She/Her', 'They/Them', 'Other'];

  const dietOptions = [
    { value: 'VEGAN', label: 'Vegan' },
    { value: 'VEGETARIAN', label: 'Vegetarian' },
    { value: 'NON_VEGAN', label: 'Non Vegan' },
  ];

  const [pronoun, setPronoun] = useState('-');
  const [shirtSize, setShirtSize] = useState('M');
  const [diet, setDiet] = useState('NON_VEGAN');
  const [allergies, setAllergies] = useState('');
  const [cv, setCv] = useState<File | null>(null);
  const [hasCv, setHasCv] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [postCV, setPostCV] = useState(false);
  const auth = useAuth();
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = auth.user?.access_token ?? '';
        const userInfo = await ProfileService.getUserInfo(token);

        setShirtSize(userInfo.shirtSize);
        setDiet(userInfo.diet);
        setAllergies(userInfo.alergies);
        setHasCv(!!userInfo.CV);
        setPronoun(userInfo.pronouns);
      } catch (error) {
        setError('Failed to fetch user information.');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchUserInfo();
    } else {
      setError(''); // Reset error message when modal closes
    }
  }, [isOpen, auth]);

  const handleSave = async () => {
    setError(''); // Clear error message before attempting to save
    try {
      const token = auth.user?.access_token ?? '';
      await ProfileService.updateUser(token, shirtSize, diet, allergies, cv, pronoun);

      onClose(); // Close the modal after saving
    } catch (error) {
      setError('Failed to update user information.');
      console.error(error);
    } finally {
      onConfirmClose(); // Reset the modal state
    }
  };

  const handleCvDownload = async () => {
    try {
      const token = auth.user?.access_token ?? '';
      const res = await ProfileService.getUserCV(token);

      const blob = new Blob([res], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'CV.pdf'); // Change to the actual filename and extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      setError('Failed to download CV.');
      console.error(error);
    }
  };

  if (loading) {
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Spinner size="xl" />
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent
        bgGradient="linear-gradient(to-t,rgba(32, 177, 130, 0.7) ,rgba(32, 177, 130, 0.8) ,rgba(0, 22, 35, 0.9) 0% ,rgba(0, 22, 35, 1) 99% )"
        color="white"
        borderRadius="20px"
        p="20px"
        width="900px"
      >
        <ModalHeader fontWeight="bold" fontSize="2xl" textAlign="center">
          Modificare date
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {error && (
            <Alert status="error" mb="1rem" color={'black'} backgroundColor="red">
              <AlertIcon color="black" />
              {error}
            </Alert>
          )}

          {/* Pronoun selection */}
          <FormControl mb="1.2rem" display="flex" alignItems="flex-start" flexDirection={'column'}>
            <HStack px="0">
              <FormLabel mb="0" mr="1rem">
                Pronume:
              </FormLabel>
              <Select
                bg="rgba(32, 177, 130, 0.2)"
                borderWidth="2px"
                borderColor="rgba(32, 177, 130, 1)"
                color="white"
                borderRadius="5px"
                width="150px"
                value={pronoun}
                onChange={e => setPronoun(e.target.value)}
                sx={{
                  option: {
                    backgroundColor: '#021a28',
                    borderColor: 'rgba(32, 177, 130, 1)',
                    color: 'white',
                  },
                }}
              >
                {pronouns.map(size => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </Select>
            </HStack>
          </FormControl>

          {/* Shirt size selection */}
          <FormControl mb="1.5rem" display="flex" alignItems="center">
            <FormLabel mb="0" mr="1rem">
              Mărime tricou:
            </FormLabel>
            <Select
              bg="rgba(32, 177, 130, 0.1)"
              color="white"
              borderRadius="5px"
              borderWidth="2px"
              borderColor="rgba(32, 177, 130, 1)"
              width="100px"
              value={shirtSize}
              onChange={e => setShirtSize(e.target.value)}
              sx={{
                option: {
                  backgroundColor: '#021a28',
                  borderColor: 'rgba(32, 177, 130, 1)',
                  color: 'white',
                },
              }}
            >
              {shirtSizes.map(size => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>
          </FormControl>

          {/* CV Upload */}
          <Box
            display="flex"
            alignItems="center"
            mb="1.5rem"
            flexDirection="column"
            placeItems="start"
          >
            <HStack alignContent="center" justifyContent="center">
              <FormLabel mb="1" flex="1">
                <HStack>
                  <Text>CV:</Text>
                  <Text color="gray">(only .pdf)</Text>
                </HStack>
              </FormLabel>
              {hasCv && (
                <>
                  <Button colorScheme="blue" onClick={handleCvDownload}>
                    Get CV
                  </Button>
                  <Button colorScheme="blue" onClick={() => setPostCV(true)}>
                    Post new CV
                  </Button>
                </>
              )}
            </HStack>
            {(postCV || !hasCv) && (
              <Input
                type="file"
                accept=".pdf"
                w="100%"
                mt="2vh"
                bg="rgba(32, 177, 130, 0.1)"
                borderWidth="2px"
                borderColor="rgba(32, 177, 130, 1)"
                height="10vh"
                onChange={e => setCv(e.target.files?.[0] || null)}
              />
            )}
          </Box>

          {/* Diet selection */}
          <FormControl mb="1.5rem">
            <FormLabel>Regim alimentar:</FormLabel>
            <Select
              size="sm"
              bg="rgba(32, 177, 130, 0.1)"
              borderWidth="2px"
              borderColor="rgba(32, 177, 130, 1)"
              color="white"
              borderRadius="5px"
              height={10}
              value={diet}
              onChange={e => setDiet(e.target.value)}
              sx={{
                option: {
                  backgroundColor: '#021a28',
                  color: 'white',
                },
              }}
            >
              {dietOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </FormControl>

          {/* Allergies Textarea */}
          <FormControl mb="1.5rem">
            <FormLabel>Alte preferințe alimentare:</FormLabel>
            <Box mb={isFocused ? { base: '20rem', md: '0' } : '0'}>
              <Textarea
                bg="rgba(32, 177, 130, 0.1)"
                borderWidth="2px"
                borderColor="rgba(32, 177, 130, 1)"
                placeholder={allergies}
                borderRadius="20px"
                value={allergies}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onChange={e => setAllergies(e.target.value)}
              />
            </Box>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            mr="auto"
            bg="rgba(32, 177, 130, 0.7)"
            borderWidth="2px"
            borderColor="rgba(32, 177, 130, 1)"
            onClick={onClose}
          >
            Anuleaza
          </Button>
          <Button
            bg="rgba(32, 177, 130, 0.7)"
            borderWidth="2px"
            borderColor="rgba(32, 177, 130, 1)"
            onClick={handleSave}
          >
            Salvează
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpdateProfileModal;
